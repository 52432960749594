import React, { useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { localApi, credentials } from "utils/imgApi";
import Slide from "@material-ui/core/Slide";
import stylesPrint from "assets/jss/material-dashboard-pro-react/views/crmUserListPrintStyle";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyleDailog = makeStyles(styleDailog);
const useStylePrint = makeStyles(stylesPrint);
const useSty = makeStyles(styCheckbox);
const useStyle = makeStyles(styDrop);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function ContactMonth() {
  const [dataOrders, setDataOrder] = React.useState([]);

  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makChecked, setMakChecked] = React.useState("");

  const [FromDate, setFromDate] = React.useState("");
  const [ToDate, setToDate] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const classCheck = useSty();
  const classTable = useStylePrint();
  const classeDailog = useStyleDailog();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const handleFromDatePicker = (FromDate) => {
    setFromDate(FromDate);
  };

  const handleToDatePicker = (ToDate) => {
    setToDate(ToDate);
  };

  const handleCheckBoxMaking = (event, or) => {
    // setCheckBoxmaking(e.target.checked);
    // const { checked } = event.target;

    setCheckBoxmaking((prev) => {
      if (prev.includes(or)) {
        return prev.filter((x) => x !== or);
      } else {
        return [...prev, or];
      }
    });

    setMakChecked((prev) => {
      if (prev.includes(or?.id)) {
        return prev.filter((x) => x !== or?.id);
      } else {
        return [...prev, or?.id];
      }
    });
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleSearchDate = async () => {
    // console.log("From", formatDate(FromDate._d));
    // console.log("To", formatDate(ToDate._d));

    const api =
      localApi +
      `/contact/list/byCrmReferenceIdBetweenDate/${userObj.id}/${formatDate(
        FromDate._d
      )}/${formatDate(ToDate._d)}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    // console.log(getResult);
    setDataOrder(
      getResult.map((or, key) => {
        return {
          sino: key + 1,
          company: or.company,
          mobile: or.mobile,
          whatsapp: or.whatsapp,
          email: or.email,
          type: or.type,
          city: or.city,
          state: or.state,
          actions: (
            <div className="actions-right">
              <FormControlLabel
                key={or.id}
                control={
                  <Checkbox
                    tabIndex={-1}
                    // checked={checkBoxMaking.includes(or)}
                    name={or}
                    onChange={(e) => {
                      handleCheckBoxMaking(e, or);
                    }}
                    checkedIcon={<Check className={classCheck.checkedIcon} />}
                    icon={<Check className={classCheck.uncheckedIcon} />}
                    classes={{
                      checked: classCheck.checkedRose,
                      root: classCheck.checkRoot,
                    }}
                  />
                }
                classes={{
                  label:
                    classCheck.label +
                    (makingCheckboxMaking === "error"
                      ? " " + classCheck.labelError
                      : ""),
                }}
              // label={making.description + "> " + making.price}
              />
              {/* {or.balance === 0 ? <span style={{color: "green"}}>Paid</span> : <span style={{color: "red"}}>Pending</span>} */}
              <Button
                justIcon
                round
                simple
                // onClick={() => {
                //   setNoticeModal(true);
                //   setOrderId(or.id);
                //   setOrderPayment(or.payment);
                // }}
                color="danger"
                className="remove"
              >
                {/* <VisibilityIcon /> */}
              </Button>{" "}
            </div>
          ),
        };
      })
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Contact Month List</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={6} sm={6} md={4}>
                <InputLabel className={classe.label}>From Date</InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                    inputProps={{
                      placeholder: "From Date Picker Here",
                    }}
                    onChange={(FromDate) => handleFromDatePicker(FromDate)}
                    name="FromDate"
                    value={FromDate}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={4}>
                <InputLabel className={classe.label}>To Date</InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                    inputProps={{
                      placeholder: "To Picker Here",
                    }}
                    onChange={(ToDate) => handleToDatePicker(ToDate)}
                    name="ToDate"
                    value={ToDate}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              <Button color="rose" round onClick={handleSearchDate}>
                Search
              </Button>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      {dataOrders.length != "0" ?
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "SI.No.",
                    accessor: "sino",
                  },
                  {
                    Header: "Company",
                    accessor: "company",
                  },
                  {
                    Header: "Mobile",
                    accessor: "mobile",
                  },
                  {
                    Header: "Whatsapp",
                    accessor: "whatsapp",
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                  },
                  {
                    Header: "Type",
                    accessor: "type",
                  },
                  {
                    Header: "City",
                    accessor: "city",
                  },
                  {
                    Header: "State",
                    accessor: "state",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={dataOrders}
              />
            </CardBody>
          </Card>
        </GridItem> : ""}
      <div>
        {dataOrders.length != "0" ?
          <Button
            color="rose"
            round
            // href={`/view-crm-print?city=adnoi&firstname=saleem&createDate=2023-03-17`}
            onClick={() =>
              history.push({
                pathname: `/view-crm-print`,
                state: checkBoxMaking,
              })
            }
          >
            print
          </Button> : ""}
      </div>
    </GridContainer>
  );
}
