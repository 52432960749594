import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { localApi } from "utils/imgApi";
import { produce } from "immer";
import axios from "axios";
import Selects from "react-select";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleCheck = makeStyles(styleCheck);
const useStyl = makeStyles(styDrop);

export default function CallMultiAllocation() {
  const [itemClient, setItemClient] = React.useState([]);
  const [itemClient1, setItemClient1] = React.useState([]);
  const [itemClientAll, setItemClientAll] = React.useState([]);
  const [callSub, setCallSub] = React.useState([]);
  const [simpleSelectExecutive, setSimpleSelectExecutive] = React.useState("");
  const [simpleSelectAllocation, setSimpleSelectAllocation] = React.useState(
    ""
  );
  const [simpleSelectAllocationErr, setSimpleSelectAllocationErr] = React.useState("");
  const [contactDetails, setContactDetails] = React.useState("");
  const [simpleSelectCategory, setSimpleSelectCategory] = React.useState("");
  const [
    simpleSelectCallSubStatus,
    setSimpleSelectCallSubStatus,
  ] = React.useState("");
  const [simpleSelectNextAction, setSimpleSelectNextAction] = React.useState(
    ""
  );
  const [AfterDate, setAfterDate] = React.useState("");
  const [AfterTime, setAfterTime] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);

  const [checkedBox, setCheckedBox] = React.useState("");
  const [checkedBoxErr, setCheckedBoxErr] = React.useState("");

  const classes = useStyles();
  const classSweet = useStyle();
  const classCheck = useStyleCheck();
  const classe = useStyl();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function formatTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  async function getCrmData() {
    const api = localApi + `/register/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(api);
    const getResult = await results.json();
    setItemClient(
      getResult.filter((r) => r.type === "Crm" && r.id === userObj.id)
    );
    setItemClient1(
      getResult.filter((r) => r.type === "Crm" && r.id !== userObj.id)
    );
    // setItemClientAll(getResult)
    // console.log(getResult);
  }

  useEffect(() => {
    getCrmData();
  }, []);

  const handleSimpleExecutive = (simpleSelectExecutive) => {
    if (simpleSelectExecutive.value) {
      let newSpli = simpleSelectExecutive.value.split("-");
      const ids = itemClient.find((f) => f.mobile === newSpli[1]);
      // console.log(ids);
      setContactDetails(ids);
      //   setSimpleSelectCategory(ids && ids.type);
    }
    setSimpleSelectExecutive(simpleSelectExecutive);
  };

  const handleSimpleCategory = async (e) => {
    const { value } = e.target;
    setSimpleSelectCategory(value);
    const apiAllow =
      localApi +
      `/callallocation/list/byCrmReferenceIdAndSubStatus/${userObj.id}/${value}`;
    const resultAllow = await fetch(apiAllow);
    const getResultAllow = await resultAllow.json();
    setCallSub(getResultAllow);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if(checked){
      setCheckedBox(checked);
    }else{
      setCheckedBox(checked);
    }
    let tempUser = callSub.map((user) =>
      user.id == name ? { ...user, isChecked: checked } : user
    );
    setCallSub(tempUser);
    setCheckedBoxErr("")
  };

  const handleSimpleAllocation = (index) => (e) => {
    setSimpleSelectAllocation(e.target.value);
    setSimpleSelectAllocationErr("")
    let receivedBy = e.target.value;
    setCallSub((curlab) =>
      produce(curlab, (v) => {
        v[index].receivedBy = receivedBy;
      })
    );
    // console.log(receivedBy);
  };

  const handleSimpleCallSubStatus = (index) => (e) => {
    // setSimpleSelectCallSubStatus(e.target.value);
    let callSubStatus = e.target.value;
    setCallSub((curlab) =>
      produce(curlab, (v) => {
        v[index].callSubStatus = callSubStatus;
      })
    );
    // console.log(callSubStatus);
  };

  const openAPi = [
    { id: 1, callSubStatus: "ACTIVE" },
    { id: 2, callSubStatus: "HOLD" },
    { id: 3, callSubStatus: "PENDING" },
    { id: 4, callSubStatus: "UNDER PROCESS" },
    { id: 5, callSubStatus: "PAYMENT" },
  ];

  const actionApi = [
    { id: 1, action: "CALL" },
    { id: 2, action: "E MAIL" },
    { id: 3, action: "SMS" },
    { id: 4, action: "SPECIFY LATER" },
    { id: 5, action: "SAMPLE" },
    { id: 6, action: "VISIT" },
  ];

  const handleSimpleNextAction = (index) => (e) => {
    // setSimpleSelectNextAction(e.target.value);
    let action = e.target.value;
    setCallSub((curlab) =>
      produce(curlab, (v) => {
        v[index].action = action;
      })
    );
    // console.log(action);
  };

  const handleAfterDate = (index) => (AfterDate) => {
    // setAfterDate(AfterDate);
    let afterdate = formatDate(AfterDate._d);
    setCallSub((curlab) =>
      produce(curlab, (v) => {
        v[index].afterdate = afterdate;
      })
    );
    // console.log(afterdate);
  };

  const handleAfterTime = (index) => (AfterTime) => {
    // setAfterTime(AfterTime);
    let aftertime = formatTime(AfterTime._d);
    setCallSub((curlab) =>
      produce(curlab, (v) => {
        v[index].aftertime = aftertime;
      })
    );
    // console.log(formatTime(aftertime));
  };

  const handleClick = (e) => {
    const callApi = callSub.filter((f) => f.isChecked === true);

    const callApi1 = callApi.map((m) => {
      return callApi.find((f) => f.id === m.id);
    });

    const callId = callApi1.map((f) => {
      return f.id;
    });

    // console.log(callId);

    // console.log(
    //   callApi.map((m) => {
    //     const ch = callApi.find((f) => f.id === m.id);
    //     const ids = itemClient1.find((f) => f.id === ch.receivedBy);
    //     // console.log(ch);
    //     return {
    //       adminId: ch.adminId,
    //       callreferenceId: ch.callreferenceId || ch.id,
    //       crmReferenceId: simpleSelectAllocation ? ids.id : ch.crmReferenceId,
    //       firstname: ch.firstname,
    //       lastname: ch.lastname,
    //       mobile: ch.mobile,
    //       whatsapp: ch.whatsapp,
    //       receivedBy: simpleSelectAllocation
    //         ? ids.firstname + " " + ids.lastname
    //         : ch.receivedBy,
    //       registerId: ch.registerId,
    //       productId: ch.productId,
    //       callReceiptRemark: ch.callReceiptRemark,
    //       suggestedActionRemark: ch.suggestedActionRemark,
    //       createdate: formatDate(new Date()),
    //       currentdate:
    //         new Date().toLocaleDateString() +
    //         " " +
    //         new Date().toLocaleTimeString(),
    //       callStatus: ch.callStatus,
    //       callSubStatus: ch.callSubStatus,
    //       action: ch.action,
    //       afterdate: ch.afterdate,
    //       aftertime: ch.aftertime,
    //     };
    //   })
    // );

    e.preventDefault();
    let errorCount = 0;
    if (simpleSelectAllocation === "" || !simpleSelectAllocation) {
      errorCount++;
      setSimpleSelectAllocationErr("Please Select Allocation");
    }
    if(checkedBox == false){
      errorCount++;
      setCheckedBoxErr("Please Select Check Box");
    }
    if (errorCount === 0) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(
        callApi.map((m) => {
          const ch = callApi.find((f) => f.id === m.id);
          const ids = itemClient1.find((f) => f.id === ch.receivedBy);
          // console.log(ch);
          return {
            adminId: ch.adminId,
            callreferenceId: ch.callreferenceId || ch.id,
            crmReferenceId: simpleSelectAllocation ? ids.id : ch.crmReferenceId,
            firstname: ch.firstname,
            lastname: ch.lastname,
            mobile: ch.mobile,
            whatsapp: ch.whatsapp,
            receivedBy: simpleSelectAllocation
              ? ids.firstname + " " + ids.lastname
              : ch.receivedBy,
            registerId: ch.registerId,
            productId: ch.productId,
            callReceiptRemark: ch.callReceiptRemark,
            suggestedActionRemark: ch.suggestedActionRemark,
            createdate: formatDate(new Date()),
            currentdate:
              new Date().toLocaleDateString() +
              " " +
              new Date().toLocaleTimeString(),
            callStatus: ch.callStatus,
            callSubStatus: ch.callSubStatus,
            action: ch.action,
            afterdate: ch.afterdate,
            aftertime: ch.aftertime,
          };
        })
      ),
    };

    fetch(localApi + `/callallocation/addAll`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.message === "Data Inserted Successfully !!!") {
          callId.forEach((x) =>
            axios
              .put(localApi + `/callallocation/update/byAllocation/${x}`)
              .then((res) => {
                console.log(res.data);
              })
          );
          alertTypeOps("success");
          alertMsgOps("Succesfully registerd!");
          setSubmitted(true);
        }
      });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer>
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PersonPinCircleIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Call Multi Allocation</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <label className={classe.selectLabel}>Executive</label>
                    <Selects
                      styles={customStyles}
                      style={select}
                      required={true}
                      placeholder="Select Executive..."
                      value={simpleSelectExecutive}
                      name="simpleSelectExecutive"
                      onChange={handleSimpleExecutive}
                      options={
                        itemClient &&
                        itemClient.map((c) => {
                          return {
                            label:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                            value:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                          };
                        })
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Sub Status...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCategory}
                        onChange={handleSimpleCategory}
                        inputProps={{
                          name: "simpleSelectCategory",
                          id: "simple-select-category",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Sub Status
                        </MenuItem>
                        {openAPi.map((t) => (
                          <MenuItem
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                            key={t.id}
                            value={t.callSubStatus}
                          >
                            {t.callSubStatus}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
        {callSub &&
          callSub.map((c, index) => (
            <GridItem xs={12} sm={12} md={12} key={c.id}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    {/* <PersonPinCircleIcon /> */}
                    {index + 1}
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Final Status</small>
                    <FormControlLabel
                      key={c.id}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          name={c.id}
                          checked={c?.isChecked || false}
                          onChange={handleChange}
                          checkedIcon={
                            <Check className={classCheck.checkedIcon} />
                          }
                          icon={<Check className={classCheck.uncheckedIcon} />}
                          classes={{
                            checked: classCheck.checkedRose,
                            root: classCheck.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label:
                          classCheck.label +
                          (makingCheckboxMaking === "error"
                            ? " " + classCheck.labelError
                            : ""),
                      }}
                    />
                    <small>{c.firstname + " " + c.lastname + " " + c.id}</small>
                  </h3>
                  <span style={{ color: "red" }}>{checkedBoxErr}</span>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Executive for Allocation...
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={c.receivedBy || simpleSelectAllocation}
                            onChange={handleSimpleAllocation(index)}
                            inputProps={{
                              name: "stateSelectExecutiveforAllocation",
                              id: "simple-select-Executive-for-Allocation",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Executive for Allocation
                            </MenuItem>
                            {itemClient1.map((o) => (
                              <MenuItem
                                classes={{
                                  root: classe.selectMenuItem,
                                  selected: classe.selectMenuItemSelected,
                                }}
                                key={o.id}
                                value={o.id}
                              >
                                {o.firstname + " " + o.lastname}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>{simpleSelectAllocationErr}</span>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Call Sub Status...
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={c.callSubStatus}
                            onChange={handleSimpleCallSubStatus(index)}
                            inputProps={{
                              name: "stateSelectCallSubStatus",
                              id: "simple-select-call-sub-status",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select Call Sub Status
                            </MenuItem>
                            {openAPi.map((o) => (
                              <MenuItem
                                classes={{
                                  root: classe.selectMenuItem,
                                  selected: classe.selectMenuItemSelected,
                                }}
                                key={o.id}
                                value={o.callSubStatus}
                              >
                                {o.callSubStatus}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Next Actoin...
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={c.action}
                            onChange={handleSimpleNextAction(index)}
                            inputProps={{
                              name: "stateSelectNextAction",
                              id: "simple-select-next-action",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select Next Action
                            </MenuItem>
                            {actionApi.map((a) => (
                              <MenuItem
                                classes={{
                                  root: classe.selectMenuItem,
                                  selected: classe.selectMenuItemSelected,
                                }}
                                key={a.id}
                                value={a.action}
                              >
                                {a.action}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <InputLabel className={classes.label}>When After Date</InputLabel>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            inputProps={{ placeholder: "Date Picker Here" }}
                            name="AfterDate"
                            value={c.afterdate}
                            onChange={handleAfterDate(index)}
                          />
                        </FormControl>{" "}
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <InputLabel className={classes.label}>After Time</InputLabel>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            dateFormat={false}
                            inputProps={{ placeholder: "Time Picker Here" }}
                            onChange={handleAfterTime(index)}
                            name="AfterTime"
                            value={c.aftertime}
                          />
                        </FormControl>{" "}
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          ))}
        {callSub.length != "0" ? (
          <Button color="rose" round onClick={handleClick}>
            Save
          </Button>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
}
