import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { localApi } from "utils/imgApi";
import axios from "axios";
import Selects from "react-select";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tables from "components/Table/Table.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleCheck = makeStyles(styleCheck);
const useStyl = makeStyles(styDrop);

export default function CallReport() {
  const [itemClient, setItemClient] = React.useState([]);
  const [itemClient1, setItemClient1] = React.useState([]);
  const [itemProd, setItemProd] = React.useState([]);
  const [simpleSelectContact, setSimpleSelectContact] = React.useState("");
  const [contactDetails, setContactDetails] = React.useState("");
  const [simpleSelectCategory, setSimpleSelectCategory] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [simpleSelectSource, setSimpleSelectSource] = React.useState("");
  const [simpleSelectProductItem, setSimpleSelectProductItem] = React.useState(
    ""
  );
  const [productDetails, setProductDetails] = React.useState("");
  const [image, setImage] = React.useState("");
  const [prodCategory, setProdCategory] = React.useState("");
  const [prodSize, setProdSize] = React.useState("");
  const [prodMetalGram, setProdMetalGram] = React.useState("");
  const [prodReadyGram, setProdReadyGram] = React.useState("");
  const [prodCash, setProdCash] = React.useState("");
  const [prodTax, setProdTax] = React.useState("");
  const [prodTaxCash, setProdTaxCash] = React.useState("");
  const [callReceiptRemarks, setCallReceiptRemarks] = React.useState("");
  const [suggestedActionRemaks, setSuggestedActionRemarks] = React.useState("");
  const [actionTakenRemaks, setActionTakenRemarks] = React.useState("");
  const [actionTakenRemaksErr, setActionTakenRemarksErr] = React.useState("");
  const [simpleSelectCallStatus, setSimpleSelectCallStatus] = React.useState(
    ""
  );
  const [
    simpleSelectCallStatusErr,
    setSimpleSelectCallStatusErr,
  ] = React.useState("");
  const [
    simpleSelectCallSubStatus,
    setSimpleSelectCallSubStatus,
  ] = React.useState("");
  const [
    simpleSelectCallSubStatusErr,
    setSimpleSelectCallSubStatusErr,
  ] = React.useState("");
  const [simpleSelectNextAction, setSimpleSelectNextAction] = React.useState(
    ""
  );
  const [
    simpleSelectNextActionErr,
    setSimpleSelectNextActionErr,
  ] = React.useState("");
  const [simpleSelectActionTaken, setSimpleSelectActionTaken] = React.useState(
    ""
  );
  const [simpleSelectAllocation, setSimpleSelectAllocation] = React.useState(
    ""
  );
  const [
    simpleSelectAllocationErr,
    setSimpleSelectAllocationErr,
  ] = React.useState("");
  const [executionAllocation, setExecutionAllocation] = React.useState("");
  const [AfterDate, setAfterDate] = React.useState("");
  const [AfterDateErr, setAfterDateErr] = React.useState("");
  const [AfterTime, setAfterTime] = React.useState("");
  const [AfterTimeErr, setAfterTimeErr] = React.useState("");
  const [ReportingTime, setReportingTime] = React.useState("");
  const [ReportingTimeErr, setReportingTimeErr] = React.useState("");
  const [LeavingTime, setLeavingTime] = React.useState("");
  const [LeavingTimeErr, setLeavingTimeErr] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  // const [refUpdate, setRefUpdate] = React.useState("");

  const [callAllowAction, setCallAllowAction] = React.useState([]);

  const classes = useStyles();
  const classSweet = useStyle();
  const classCheck = useStyleCheck();
  const classe = useStyl();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function arrSorts(arr) {
    arr.sort((a, b) => a - b);
    arr.reverse();
    return arr;
  }

  async function getCrmReceipt() {
    // const api = localApi + `/callreceipt/list/byCrmReferenceIdAndCallStatusAndAllocation/${userObj.id}/OPEN/false`;
    // const results = await fetch(api);
    // const getResult = await results.json();
    // setItemClient(getResult);

    const apiAllow =
      localApi +
      `/callallocation/list/byCrmReferenceIdAndCallStatusAndAllocation/${userObj.id}/OPEN/false`;
    const resultsAllow = await fetch(apiAllow);
    const getResultAllow = await resultsAllow.json();
    // console.log(getResultAllow);
    setItemClient(getResultAllow);
  }

  useEffect(() => {
    getCrmReceipt();
  }, []);

  async function getProduct() {
    const api = localApi + `/product/list/byAdminId/${userObj.admin.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItemProd(getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getProduct();
  }, []);

  const handleSimpleContact = async (simpleSelectContact) => {
    // console.log(simpleSelectContact.value);
    if (simpleSelectContact.value) {
      let newSpli = simpleSelectContact.value.split("-");
      // console.log(newSpli);
      const ids = itemClient.find((f) => f.mobile == newSpli[1]);
      // console.log(ids);
      setContactDetails(ids);
      setSimpleSelectProductItem(ids.productId);
      const api = localApi + `/product/findById/${ids.productId}`;
      const result = await fetch(api);
      const getResult = await result.json();
      // console.log(getResult);
      setImage(
        getResult.productImage[0].cropImageUrl +
          "?id=" +
          getResult.productImage[0].imgId
      );
      // setProductDetails(getResult);
      setProdCategory(getResult.productCategory.title);
      setProdSize(getResult.productParent.title);
      setProdMetalGram(getResult.metalGram);
      setProdReadyGram(getResult.readyGram);
      setProdCash(getResult.cashPrice.toFixed(2));
      setProdTax(getResult.taxAll.toFixed(2));
      setProdTaxCash(getResult.taxInclude.toFixed(2));

      setCallReceiptRemarks(ids.callReceiptRemark);
      setSuggestedActionRemarks(ids.suggestedActionRemark);
      setSimpleSelectActionTaken(ids.action);
      // console.log(ids.callreferenceId);

      const apiCall =
        localApi + `/callreceipt/find/byId/${ids.callreferenceId}`;
      const resultCall = await fetch(apiCall);
      const getResultCall = await resultCall.json();
      // console.log(getResultCall.callAllow);
      setCallAllowAction(
        arrSorts(
          getResultCall.callAllow.filter((f) => f.actionTakenRemark != null)
        )
      );
    }
    setSimpleSelectContact(simpleSelectContact);
  };

  const handleSimpleProductItem = (e) => {
    const { value } = e.target;
    setSimpleSelectProductItem(value);
    const itemz = itemProd.find((item) => item.id == value);
    setImage(
      itemz.productImage[0].cropImageUrl + "?id=" + itemz.productImage[0].imgId
    );
    // setProductDetails(itemz);
    setProdCategory(itemz.productCategory.title);
    setProdSize(itemz.productParent.title);
    setProdMetalGram(itemz.metalGram);
    setProdReadyGram(itemz.readyGram);
    setProdCash(itemz.cashPrice.toFixed(2));
    setProdTax(itemz.taxAll.toFixed(2));
    setProdTaxCash(itemz.taxInclude.toFixed(2));
  };

  const handleSimpleCallStatus = (e) => {
    setSimpleSelectCallStatus(e.target.value);
    setSimpleSelectCallStatusErr("");
  };

  const handleSimpleCallSubStatus = (e) => {
    setSimpleSelectCallSubStatus(e.target.value);
    setSimpleSelectCallSubStatusErr("");
  };

  const openAPi = [
    { id: 1, subStatus: "ACTIVE" },
    { id: 2, subStatus: "HOLD" },
    { id: 3, subStatus: "PENDING" },
    { id: 4, subStatus: "UNDER PROCESS" },
    { id: 5, subStatus: "PAYMENT" },
  ];

  const closeApi = [
    { id: 1, subStatus: "CANCLED" },
    { id: 2, subStatus: "SUCCESSFUL" },
    { id: 3, subStatus: "UNSUCCESSFUL" },
  ];

  const handleSimpleActionTaken = (e) => {
    setSimpleSelectActionTaken(e.target.value);
  };

  const handleSimpleNextAction = (e) => {
    setSimpleSelectNextAction(e.target.value);
    setSimpleSelectNextActionErr("");
  };

  const handleAfterDate = (AfterDate) => {
    setAfterDate(AfterDate);
    setAfterDateErr("");
  };

  const handleAfterTime = (AfterTime) => {
    setAfterTime(AfterTime);
    setAfterTimeErr("");
  };

  const handleReportingTime = (ReportingTime) => {
    setReportingTime(ReportingTime);
    setReportingTimeErr("");
  };

  const handleLeavingTime = (LeavingTime) => {
    setLeavingTime(LeavingTime);
    setLeavingTimeErr("");
  };

  async function getRegister() {
    const api = localApi + `/register/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(api);
    const getResult = await results.json();
    setItemClient1(
      getResult.filter((r) => r.type === "Crm" && r.id !== userObj.id)
    );
  }

  useEffect(() => {
    getRegister();
  }, []);

  const handleSimpleAllocation = (e) => {
    setSimpleSelectAllocation(e.target.value);
    setSimpleSelectAllocationErr("");
    const { value } = e.target;
    const itemz = itemClient1.find((item) => item.id == value);
    // console.log(itemz);
    setExecutionAllocation(itemz);
  };

  const SubmitCallSave = async (e) => {
    // console.log(contactDetails);

    const api =
      localApi +
      `/callallocation/byCallReference/${
        simpleSelectCallStatus === "CLOSED"
          ? contactDetails.callreferenceId
          : ""
      }`;
    const results = await fetch(api);
    const getResult = await results.json();
    if (getResult.status == 404) {
      console.log();
    } else {
      const refUpdate = getResult.map((m) => {
        return m.id;
      });
      // setRefUpdate(
      //   getResult.map((m) => {
      //     return m.id;
      //   })
      // );
      refUpdate.forEach((x) =>
        axios
          .put(localApi + `/callallocation/update/byFinalStatus/${x}`)
          .then((res) => {
            console.log(res.data);
          })
      );
    }

    // const data = {
    //   registerId: contactDetails.registerId,
    //   firstname: contactDetails.firstname,
    //   lastname: contactDetails.lastname,
    //   mobile: contactDetails.mobile,
    //   whatsapp: contactDetails.whatsapp,
    //   adminId: userObj.admin.id,
    //   allocation: simpleSelectCallStatus === "OPEN" ? false : true,
    //   createdate: formatDate(new Date()),
    //   currentdate:
    //     new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
    //   receivedBy:
    //     executionAllocation.firstname + " " + executionAllocation.lastname,
    //   productId: simpleSelectProductItem,
    //   callReceiptRemark: callReceiptRemarks,
    //   suggestedActionRemark: suggestedActionRemaks,
    //   actionTakenRemark: actionTakenRemaks,
    //   reportingtime: ReportingTime._d.toLocaleTimeString(),
    //   leavingtime: LeavingTime._d.toLocaleTimeString(),
    //   callStatus: simpleSelectCallStatus,
    //   callSubStatus: simpleSelectCallSubStatus,
    //   action: simpleSelectCallStatus === "OPEN" ? simpleSelectNextAction : null,
    //   afterdate:
    //     simpleSelectCallStatus === "OPEN" ? formatDate(AfterDate._d) : null,
    //   aftertime:
    //     simpleSelectCallStatus === "OPEN"
    //       ? AfterTime._d.toLocaleTimeString()
    //       : null,
    // };

    // console.log(JSON.stringify(data));

    // console.log(simpleSelectCallStatus === "OPEN" ? true : false);
    // console.log(simpleSelectCallStatus === "OPEN" ? 1 : 0);

    e.preventDefault();
    let errorCount = 0;
    if (actionTakenRemaks === "" || !actionTakenRemaks) {
      errorCount++;
      setActionTakenRemarksErr("Please Select Action Taken Remark");
    }
    if (ReportingTime === "" || !ReportingTime) {
      errorCount++;
      setReportingTimeErr("Please Select Reporting Time");
    }
    if (LeavingTime === "" || !LeavingTime) {
      errorCount++;
      setLeavingTimeErr("Please Select Leaving Time");
    }
    if (simpleSelectCallStatus === "" || !simpleSelectCallStatus) {
      errorCount++;
      setSimpleSelectCallStatusErr("Please Select Call Status");
    }
    if (simpleSelectCallSubStatus === "" || !simpleSelectCallSubStatus) {
      errorCount++;
      setSimpleSelectCallSubStatusErr("Please Select Call Sub Status");
    }
    if (
      simpleSelectCallStatus === "OPEN"
        ? simpleSelectNextAction === ""
        : "" || simpleSelectCallStatus === "OPEN"
        ? !simpleSelectNextAction
        : ""
    ) {
      errorCount++;
      setSimpleSelectNextActionErr("Please Select Next Action");
    }
    if (
      simpleSelectCallStatus === "OPEN"
        ? AfterDate === ""
        : "" || simpleSelectCallStatus === "OPEN"
        ? !AfterDate
        : ""
    ) {
      errorCount++;
      setAfterDateErr("Please Select After Date");
    }
    if (
      simpleSelectCallStatus === "OPEN"
        ? AfterTime === ""
        : "" || simpleSelectCallStatus === "OPEN"
        ? !AfterTime
        : ""
    ) {
      errorCount++;
      setAfterTimeErr("Please Select After Time");
    }
    if (
      simpleSelectCallStatus === "OPEN"
        ? simpleSelectAllocation === ""
        : "" || simpleSelectCallStatus === "OPEN"
        ? !simpleSelectAllocation
        : ""
    ) {
      errorCount++;
      setSimpleSelectAllocationErr("Please Select Executive Allocation");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          registerId: contactDetails.registerId,
          callreferenceId: contactDetails.callreferenceId,
          firstname: contactDetails.firstname,
          lastname: contactDetails.lastname,
          mobile: contactDetails.mobile,
          whatsapp: contactDetails.whatsapp,
          adminId: userObj.admin.id,
          allocation: simpleSelectCallStatus === "OPEN" ? false : true,
          finalStatus: simpleSelectCallStatus === "OPEN" ? false : true,
          createdate: formatDate(new Date()),
          currentdate:
            new Date().toLocaleDateString() +
            " " +
            new Date().toLocaleTimeString(),
          receivedBy: simpleSelectAllocation
            ? executionAllocation.firstname + " " + executionAllocation.lastname
            : contactDetails.receivedBy,
          productId: simpleSelectProductItem,
          callReceiptRemark: callReceiptRemarks,
          suggestedActionRemark: suggestedActionRemaks,
          actionTakenRemark: actionTakenRemaks,
          reportingtime: ReportingTime._d.toLocaleTimeString(),
          leavingtime: LeavingTime._d.toLocaleTimeString(),
          callStatus: simpleSelectCallStatus,
          callSubStatus: simpleSelectCallSubStatus,
          action:
            simpleSelectCallStatus === "OPEN" ? simpleSelectNextAction : null,
          afterdate:
            simpleSelectCallStatus === "OPEN" ? formatDate(AfterDate._d) : null,
          aftertime:
            simpleSelectCallStatus === "OPEN"
              ? AfterTime._d.toLocaleTimeString()
              : null,
        }),
      };
      const requestOptionsClosed = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          callStatus: simpleSelectCallStatus,
          callSubStatus: simpleSelectCallSubStatus,
        }),
      };
      // console.log(requestOptions);

      fetch(
        localApi +
          `/callallocation/add/byCrmReferenceId/${
            simpleSelectAllocation ? executionAllocation.id : userObj.id
          }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            axios
              .put(
                localApi +
                  `/callallocation/update/byAllocation/${contactDetails.id}`
              )
              .then((res) => {
                // console.log(res.data);
                if (res.data.message === "Data Updated Successfully !!!") {
                  axios
                    .put(
                      localApi +
                        `/register/update/byIdAndOpenCloseCrmTrueOrfalse/${
                          contactDetails.registerId
                        }/${
                          simpleSelectCallStatus === "OPEN" ? "true" : "false"
                        }`
                    )
                    .then((res) => {
                      // console.log(res.data);
                      if (
                        res.data.message === "Data Updated Successfully !!!"
                      ) {
                        fetch(
                          localApi +
                            `/callreceipt/update/byFinalStatus/${
                              simpleSelectCallStatus === "CLOSED"
                                ? contactDetails.callreferenceId
                                : ""
                            }`,
                          requestOptionsClosed
                        )
                          .then((response) => response.json())
                          .then((data) => {
                            console.log(res.data);
                            // if (
                            //   data.message ===
                            //   "Data Updated Successfully !!!"
                            // ) {
                            //   alertTypeOps("success");
                            //   alertMsgOps("Succesfully registerd!");
                            //   setSubmitted(true);
                            // }
                          });
                      }
                    });
                }
              });
            alertTypeOps("success");
            alertMsgOps("Succesfully registerd!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    setSimpleSelectContact("");
    setSimpleSelectCategory("");
    setSimpleSelectSource("");
  }, [itemClient]);

  return (
    <div>
      <GridContainer>
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PersonPinCircleIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Call Report</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4}>
                    <label className={classe.selectLabel}>
                      Select Client{" "}
                      {contactDetails.id ? ":- " + contactDetails.id : ""}
                    </label>
                    <Selects
                      styles={customStyles}
                      style={select}
                      required={true}
                      placeholder="Select Client..."
                      value={simpleSelectContact}
                      name="simpleSelectClient"
                      onChange={handleSimpleContact}
                      options={
                        itemClient &&
                        itemClient.map((c) => {
                          return {
                            label:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                            value:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                          };
                        })
                      }
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>

        {simpleSelectContact ? (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Product Item</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Product Item...
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={simpleSelectProductItem}
                            onChange={handleSimpleProductItem}
                            inputProps={{
                              name: "simpleSelectProductItem",
                              id: "simple-select-product-item",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select Product
                            </MenuItem>
                            {itemProd &&
                              itemProd.map((item) => (
                                <MenuItem
                                  key={item.id}
                                  value={item.id}
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                >
                                  {item.id}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      {simpleSelectProductItem ? (
                        <GridItem xs={12} sm={6} md={4}>
                          <img
                            src={image}
                            alt="Product Image"
                            style={{ width: "120px", height: "134px" }}
                          />
                        </GridItem>
                      ) : (
                        ""
                      )}
                      {simpleSelectProductItem ? (
                        <GridItem xs={12} sm={6} md={4}>
                          Product Description
                          <p className={classes.cardIconTitle}>
                            Category:- {prodCategory} <br />
                            Size:- {prodSize} <br />
                            Metal Gram:- {prodMetalGram} <br />
                            Ready Gram:- {prodReadyGram} <br />
                            Cash Price:- {prodCash} <br />
                            Tax:- {prodTax} <br />
                            TaxInclude:- {prodTaxCash}
                          </p>
                        </GridItem>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Call Receipt And Suggested Action Remarks</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h5>{callReceiptRemarks}</h5>
                      </GridItem>
                    </GridContainer>
                    <hr />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h5>{suggestedActionRemaks}</h5>
                      </GridItem>
                      <GridItem>
                        <Tables
                          tableHead={[
                            <span className={classes.OrderlistDescription}>
                              SI.No
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              Call Id
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              Received By
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              Action Taken Remark
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              After Date
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              After Time
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              call Status
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              Call Sub Status
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              Action
                            </span>,
                          ]}
                          tableData={callAllowAction.map((c, index) => [
                            <span className={classes.OrderlistDescription}>
                              {index + 1}
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              {c.id}
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              {c.receivedBy}
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              {c.actionTakenRemark}
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              {c.afterdate}
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              {c.aftertime}
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              {c.callStatus}
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              {c.callSubStatus}
                            </span>,
                            <span className={classes.OrderlistDescription}>
                              {c.action}
                            </span>,
                          ])}
                        />
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Action Taken Remaks</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Actoin Taken...
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={simpleSelectActionTaken}
                            onChange={handleSimpleActionTaken}
                            inputProps={{
                              name: "stateSelectActionTaken",
                              id: "simple-select-action-taken",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select Action Taken
                            </MenuItem>

                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="CALL"
                            >
                              CALL
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="E MAIL"
                            >
                              E-MAIL
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="SMS"
                            >
                              SMS
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="SPECIFY LATER"
                            >
                              SPECIFY LATER
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="SAMPLE"
                            >
                              SAMPLE
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="VISIT"
                            >
                              VISIT
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Action Taken Remaks..."
                          id="Action Taken Remaks"
                          inputProps={{
                            placeholder: "Action Taken Remaks...",
                            multiline: true,
                            rows: 5,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="Action Taken Remaks"
                          value={actionTakenRemaks}
                          onChange={(e) => {
                            setActionTakenRemarks(e.target.value);
                            setActionTakenRemarksErr("");
                          }}
                        />
                        <span style={{ color: "red" }}>
                          {actionTakenRemaksErr}
                        </span>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Timming</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <InputLabel className={classes.label}>
                          Reporting Time
                        </InputLabel>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            dateFormat={false}
                            inputProps={{ placeholder: "Reporting Time" }}
                            onChange={(ReportingTime) =>
                              handleReportingTime(ReportingTime)
                            }
                            name="ReportingTime"
                            value={ReportingTime}
                          />
                        </FormControl>
                        <span style={{ color: "red" }}>{ReportingTimeErr}</span>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <InputLabel className={classes.label}>
                          Leaving Time
                        </InputLabel>
                        <br />
                        <FormControl fullWidth>
                          <Datetime
                            dateFormat={false}
                            inputProps={{ placeholder: "Leaving Time" }}
                            onChange={(LeavingTime) =>
                              handleLeavingTime(LeavingTime)
                            }
                            name="LeavingTime"
                            value={LeavingTime}
                          />
                        </FormControl>
                        <span style={{ color: "red" }}>{LeavingTimeErr}</span>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <PersonPinCircleIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Final Status</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Call Status...
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={simpleSelectCallStatus}
                            onChange={handleSimpleCallStatus}
                            inputProps={{
                              name: "stateSelectCallStatus",
                              id: "simple-select-call-status",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select Call Status
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="OPEN"
                            >
                              OPEN
                            </MenuItem>
                            <MenuItem
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                              value="CLOSED"
                            >
                              Closed
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>
                          {simpleSelectCallStatusErr}
                        </span>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classe.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classe.selectLabel}
                          >
                            Select Call Sub Status...
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classe.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={simpleSelectCallSubStatus}
                            onChange={handleSimpleCallSubStatus}
                            inputProps={{
                              name: "stateSelectCallSubStatus",
                              id: "simple-select-call-sub-status",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classe.selectMenuItem,
                              }}
                            >
                              Select Call Sub Status
                            </MenuItem>
                            {simpleSelectCallStatus
                              ? simpleSelectCallStatus === "OPEN"
                                ? openAPi.map((o) => (
                                    <MenuItem
                                      classes={{
                                        root: classe.selectMenuItem,
                                        selected: classe.selectMenuItemSelected,
                                      }}
                                      key={o.id}
                                      value={o.subStatus}
                                    >
                                      {o.subStatus}
                                    </MenuItem>
                                  ))
                                : closeApi.map((c) => (
                                    <MenuItem
                                      classes={{
                                        root: classe.selectMenuItem,
                                        selected: classe.selectMenuItemSelected,
                                      }}
                                      key={c.id}
                                      value={c.subStatus}
                                    >
                                      {c.subStatus}
                                    </MenuItem>
                                  ))
                              : ""}
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>
                          {simpleSelectCallSubStatusErr}
                        </span>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        {simpleSelectCallStatus === "OPEN" ? (
                          <>
                            <FormControl
                              fullWidth
                              className={classe.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classe.selectLabel}
                              >
                                Select Next Actoin...
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classe.selectMenu,
                                }}
                                classes={{
                                  select: classe.select,
                                }}
                                value={simpleSelectNextAction}
                                onChange={handleSimpleNextAction}
                                inputProps={{
                                  name: "stateSelectNextAction",
                                  id: "simple-select-next-action",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classe.selectMenuItem,
                                  }}
                                >
                                  Select Next Action
                                </MenuItem>

                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="CALL"
                                >
                                  CALL
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="E MAIL"
                                >
                                  E-MAIL
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="SMS"
                                >
                                  SMS
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="SPECIFY LATER"
                                >
                                  SPECIFY LATER
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="SAMPLE"
                                >
                                  SAMPLE
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  value="VISIT"
                                >
                                  VISIT
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <span style={{ color: "red" }}>
                              {simpleSelectNextActionErr}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        {simpleSelectCallStatus === "OPEN" ? (
                          <>
                            <InputLabel className={classes.label}>
                              When After Date
                            </InputLabel>
                            {/* <br /> */}
                            <FormControl fullWidth>
                              <Datetime
                                timeFormat={false}
                                dateFormat="YYYY-MM-DD"
                                inputProps={{ placeholder: "Date Picker Here" }}
                                onChange={(AfterDate) =>
                                  handleAfterDate(AfterDate)
                                }
                                name="AfterDate"
                                value={AfterDate}
                              />
                            </FormControl>{" "}
                            <span style={{ color: "red" }}>{AfterDateErr}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        {simpleSelectCallStatus === "OPEN" ? (
                          <>
                            <InputLabel className={classes.label}>
                              After Time
                            </InputLabel>
                            {/* <br /> */}
                            <FormControl fullWidth>
                              <Datetime
                                dateFormat={false}
                                inputProps={{ placeholder: "Time Picker Here" }}
                                onChange={(AfterTime) =>
                                  handleAfterTime(AfterTime)
                                }
                                name="AfterTime"
                                value={AfterTime}
                              />
                            </FormControl>{" "}
                            <span style={{ color: "red" }}>{AfterTimeErr}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        {simpleSelectCallStatus === "OPEN" ? (
                          <>
                            <FormControl
                              fullWidth
                              className={classe.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classe.selectLabel}
                              >
                                Select Executive for Allocation...
                              </InputLabel>

                              <Select
                                MenuProps={{
                                  className: classe.selectMenu,
                                }}
                                classes={{
                                  select: classe.select,
                                }}
                                value={simpleSelectAllocation}
                                onChange={handleSimpleAllocation}
                                inputProps={{
                                  name: "stateSelectExecutiveforAllocation",
                                  id: "simple-select-Executive-for-Allocation",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classe.selectMenuItem,
                                  }}
                                >
                                  Executive for Allocation
                                </MenuItem>
                                {itemClient1.map((o) => (
                                  <MenuItem
                                    classes={{
                                      root: classe.selectMenuItem,
                                      selected: classe.selectMenuItemSelected,
                                    }}
                                    key={o.id}
                                    value={o.id}
                                  >
                                    {o.firstname + " " + o.lastname}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <span style={{ color: "red" }}>
                              {simpleSelectAllocationErr}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </GridItem>
                    </GridContainer>
                    <Button
                      color="rose"
                      onClick={SubmitCallSave}
                      className={classes.updateProfileButton}
                    >
                      Save
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
}
