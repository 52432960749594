import React, { useEffect, useState } from "react";
import { localApi } from "utils/imgApi";

function CheckboxList() {
  const [users, setUsers] = useState([]);

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const userData = [
    { name: "Jeevan" },
    { name: "Manish" },
    { name: "Prince" },
    { name: "Arti" },
    { name: "rahul" },
  ];
  
  async function getSets() {
    const api = localApi + `/contact/list/byRegisterId/${userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setUsers(getResult);
  }

  useEffect(() => {
    getSets();
    // setUsers(userData);
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "0") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
    } else {
      let tempUser = users.map((user) =>
        user.id == name ? { ...user, isChecked: checked } : user
      );
      setUsers(tempUser);
    }
  };

  const handleSubmit = () => {
    console.log(users.filter((m) => {
        return m.isChecked === true;
    }));
  };

  return (
    <div>
      <form>
        <h3>Select Users</h3>
        <div>
          <input
            type="checkbox"
            name="0"
            checked={!users.some((user) => user?.isChecked !== true)}
            onChange={handleChange}
          />
          <label>All Select</label>
        </div>
        {users.map((user, index) => (
          <div key={user.id}>
            <input
              type="checkbox"
              name={user.id}
              checked={user?.isChecked || false}
              onChange={handleChange}
            />
            <label>{user.firstname}</label>
          </div>
        ))}
      </form>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default CheckboxList;
