import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import Selects from "react-select";
import { headApi } from "utils/headApi";
import { localApi,credentials } from "utils/imgApi";
import { useHistory, useLocation } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddIcon from "@material-ui/icons/Add";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { getStates, getCities } from "utils/util";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStyl = makeStyles(styDrop);
const useStyleCheck = makeStyles(styleCheck);
const useStyle = makeStyles(style);

export default function UpdateBranch() {
  const [branchList, setBranchList] = React.useState([]);
  const [transList, setTransList] = React.useState([]);
  const [simpleBranch, setSimpleBranch] = React.useState("");
  const [simpleBranchErr, setSimpleBranchErr] = React.useState("");
  const [transId, setTransId] = React.useState("");
  const [branchName, setBranchName] = React.useState("");
  const [branchNameErr, setBranchNameErr] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [whatsApp, setWhatApp] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [gstIn, setGstIn] = React.useState("");
  const [gstInErr, setGstInErr] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [aliasName, setAliasName] = React.useState("");
  const [plotHouseNo, setPlotHouseNo] = React.useState("");
  const [areaColony, setAreaColony] = React.useState("");
  const [landMark, setLandMark] = React.useState("");
  const [road, setRoad] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [zipCodeErr, setZipCodeErr] = React.useState("");
  const [country, setCountry] = React.useState("India");
  const [active, setActive] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const [countryCode, setCountryCode] = React.useState("in");
  const [stateSelect, setStateSelect] = React.useState("");
  const [stateSelectErr, setStateSelectErr] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [citySelectErr, setCitySelectErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [simpleSelectType, setSimpleSelectType] = React.useState("");
  const [simpleSelectTypeErr, setSimpleSelectTypeErr] = React.useState("");

  const classes = useStyles();
  const classDrop = useStyl();
  const classCheck = useStyleCheck();
  const classe = useStyle();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));
  // const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

  const { state } = useLocation();
  // console.log(state);
  if(state === null){
    history.push("/admin/viewBranch");
  }

  async function getClient() {
    const api = localApi + `/transport/list/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setTransList(getResult);
    
  }

  async function getBranch() {
    const api = localApi + `/branch/list/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setBranchList(getResult);
    // console.log(getResult.user);
  }

  useEffect(() => {
    getBranch();
    getClient();
  }, []);

  async function getBranchUpdate() {
    setSimpleBranch(state && state.id);
    setTransId(state && state.id);
    setFirstName(state && state.firstName);
    setLastName(state && state.lastName);
    setSimpleSelectType(state && state.addressType);
    setBranchName(state && state.branchName);
    setMobile(state && state.mobile);
    setWhatApp(state && state.whatsApp);
    setAadharNumber(state && state.aadharNumber);
    setPanNumber(state && state.panNumber);
    setGstIn(state && state.gstIn);
    setCompany(state && state.company);
    setAliasName(state && state.aliasName);
    setPlotHouseNo(state && state.plotHouseNo);
    setAreaColony(state && state.areaColony);
    setLandMark(state && state.landMark);
    setRoad(state && state.road);
    setZipCode(state && state.zipCode);
    setCountry(state && state.country);
    setActive(state && state.active);

    const countryCodeApi = localApi + `/countrylistcode/allList`;
    const countryCodeResult = await fetch(countryCodeApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getCountryCodeResult = await countryCodeResult.json();
    const cCode = getCountryCodeResult.filter(
      (f) => f.countryName === state.country
    )[0];
    setCountryCode(cCode.countryCode);
    setStateSelect(state.state);
    const sc = getStates(cCode.countryCode).filter(
      (f) => f.name === state.state
    )[0];
    setStateCode(sc.state_code);
    setCitySelect(state.city);
  }

  useEffect(() => {
    getBranchUpdate();
  }, [state])

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    setMobileErr("");
  };

  const handleState = (event) => {
    const { value } = event.target;
    setStateSelect(value);
    setStateSelectErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCitySelectErr("");
  };

  const handleSimpleType = (event) => {
    setSimpleSelectType(event.target.value);
    setSimpleSelectTypeErr("");
  };

  const handleSimpleBranch = async (event) => {
    const { value } = event.target;
    setSimpleBranch(value);
    setSimpleBranchErr("");
    const item = branchList.find((item) => item.id === value);
    // console.log(item);
    setTransId(item.id);
    setFirstName(item.firstName);
    setLastName(item.lastName);
    setSimpleSelectType(item.addressType);
    setBranchName(item.branchName);
    setMobile(item.mobile);
    setWhatApp(item.whatsApp);
    setAadharNumber(item.aadharNumber);
    setPanNumber(item.panNumber);
    setGstIn(item.gstIn);
    setCompany(item.company);
    setAliasName(item.aliasName);
    setPlotHouseNo(item.plotHouseNo);
    setAreaColony(item.areaColony);
    setLandMark(item.landMark);
    setRoad(item.road);
    setZipCode(item.zipCode);
    setCountry(item.country);
    setActive(item.active);
    // console.log(transList.map(i => {return i.branch.filter(b => b.id === item.id)}));
    // const sCode = getStates(countryCode).find(i => i.name === item.state);
    // setStateCode(sCode.state_code);
    // setCountryCode(sCode.country_code);
    // console.log(sCode);

    const countryCodeApi = localApi + `/countrylistcode/allList`;
    const countryCodeResult = await fetch(countryCodeApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getCountryCodeResult = await countryCodeResult.json();
    const cCode = getCountryCodeResult.filter(
      (f) => f.countryName === item.country
    )[0];
    setCountryCode(cCode.countryCode);
    setStateSelect(item.state);
    const sc = getStates(cCode.countryCode).filter(
      (f) => f.name === item.state
    )[0];
    setStateCode(sc.state_code);
    setCitySelect(item.city);
  };

  const handleSimple = (event) => {
    const { value } = event.target;
    setSimpleSelect(value);
    setSimpleSelectErr("");
  };

  const handleGST = (e) => {
    const { value } = e.target;
    setGstIn(value);
    setGstInErr("");
    fetch(
      `https://sheet.gstincheck.co.in/check/028ec5a297d1ae03ba935ac49586e228/${value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.message === "GSTIN  found.") {
          setAreaColony(res.data.pradr.adr);
          setBranchName(res.data.tradeNam);
          setStateSelect(res.data.pradr.addr.stcd);
          setCitySelect(res.data.pradr.addr.dst);
        } else if (res.message === "Invalid GSTIN Number") {
          setGstInErr("Invalid GSTIN Number");
        } else if (res.message === "Credit Not Available") {
          setGstInErr("Not Available");
        }
        // console.log(res);
      });
  };

  const SubmitUpdateBranchTransport = async () => {
    
    let errorCount = 0;
    if (simpleBranch === "" || !simpleBranch) {
      errorCount++;
      setSimpleBranchErr("Select Branch Name");
    }
    
    if (branchName === "" || !branchName) {
      errorCount++;
      setBranchNameErr("Branch Name is Required");
    }

    // let regTest = /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;
    let regTest = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!regTest.test(gstIn)) {
      errorCount++;
      setGstInErr("GST No. is valid");
    }

    let gstVal = gstIn;
    let statecode = gstVal.substring(0, 2);
    let patternstatecode = /^[0-9]{2}$/;
    let threetoseven = gstVal.substring(2, 7);
    let patternthreetoseven = /^[A-Z]{5}$/;
    let seventoten = gstVal.substring(7, 11);
    let patternseventoten = /^[0-9]{4}$/;
    let Twelveth = gstVal.substring(11, 12);
    let patternTwelveth = /^[A-Z]{1}$/;
    let Thirteen = gstVal.substring(12, 13);
    let patternThirteen = /^[1-9A-Z]{1}$/;
    let fourteen = gstVal.substring(13, 14);
    let patternfourteen = /^Z$/;
    let fifteen = gstVal.substring(14, 15);
    let patternfifteen = /^[0-9A-Z]{1}$/;
    if (gstVal.length != 15) {
      setGstInErr(
        "Length should be restricted to 15 digits and should not allow anything more or less"
      );
    } else if (!patternstatecode.test(statecode)) {
      setGstInErr("First two characters of GSTIN should be Number");
    } else if (!patternthreetoseven.test(threetoseven)) {
      setGstInErr("Third to seventh characters of GSTIN should be Alphabets Capital Letter");
    } else if (!patternseventoten.test(seventoten)) {
      setGstInErr("Eighth to Eleventh characters of GSTIN should be Number");
    } else if (!patternTwelveth.test(Twelveth)) {
      setGstInErr("Twelveth character of GSTIN should be Alphabet Capital Letter");
    } else if (!patternThirteen.test(Thirteen)) {
      setGstInErr(
        "Thirteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (!patternfourteen.test(fourteen)) {
      setGstInErr("fourteen characters of GSTIN should be Alphabet Capital Letter");
    } else if (!patternfifteen.test(fifteen)) {
      setGstInErr(
        "fifteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (statecode < 1 || statecode > 37) {
      setGstInErr("Invalid First Two Characters of GSTIN");
    }

    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Mobile Number");
    }
    if (zipCode === "" || !zipCode) {
      errorCount++;
      setZipCodeErr("Please Enter Zip Code");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCitySelectErr("Please Select City Name");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateSelectErr("Please Select State Name");
    }
    if (simpleSelectType === "" || !simpleSelectType) {
      errorCount++;
      setSimpleSelectTypeErr("Please Select Address Type");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          branchName: branchName,
          addressType: simpleSelectType,
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          whatsApp: whatsApp,
          aadharNumber: aadharNumber,
          panNumber: panNumber,
          gstIn: gstIn,
          company: company,
          aliasName: aliasName,
          plotHouseNo: plotHouseNo,
          areaColony: areaColony,
          landMark: landMark,
          road: road,
          zipCode: zipCode,
          city: citySelect,
          state: stateSelect,
          country: country,
          active: active,
        }),
      };

      fetch(
        localApi + `/branch/update/transportById/${transId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Updated Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    // window.location.reload(false);
    history.push("/admin/viewBranch")
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Branch Transport</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={classe.button + " " + classe.success}
                  />
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select Branch Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={simpleBranch}
                      onChange={handleSimpleBranch}
                      inputProps={{
                        name: "simpleSelectISimplmage",
                        id: "simple-select-image",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select Branch Name...
                      </MenuItem>
                      {branchList &&
                        branchList.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classDrop.selectMenuItem,
                              selected: classDrop.selectMenuItemSelected,
                            }}
                          >
                            {item.branchName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{simpleBranchErr}</span>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select Transport Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelectImage",
                        id: "simple-select-image",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select Transport Name...
                      </MenuItem>
                      {transList &&
                        transList.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classDrop.selectMenuItem,
                              selected: classDrop.selectMenuItemSelected,
                            }}
                          >
                            {item.transportName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{simpleSelectErr}</span>
                </GridItem> */}
                {/* <GridItem xs={12} sm={12} md={12}>
                  <label className={classDrop.selectLabel}>
                    Select Transportation
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Transportation..."
                    value={transportList}
                    required={true}
                    onChange={handleClientId}
                    options={
                      transList &&
                      transList.map((c) => {
                        return {
                          label:
                            c && c.transportName
                              ? c.transportName + " - " + c.id
                              : c.id,
                          value:
                            c && c.transportName
                              ? c.transportName + " - " + c.id
                              : c.id,
                        };
                      })
                    }
                  />
                  <span style={{ color: "red" }}>{transportListErr}</span>
                </GridItem> */}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select Address Type...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={simpleSelectType}
                      onChange={handleSimpleType}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select Address Type
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="GSTIN ADDRESS"
                      >
                        GSTIN ADDRESS
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="DELIVERY ADDRESS"
                      >
                        DELIVERY ADDRESS
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classDrop.selectMenuItem,
                          selected: classDrop.selectMenuItemSelected,
                        }}
                        value="OTHER ADDRESS"
                      >
                        OTHER ADDRESS
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{simpleSelectTypeErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Branch Name..."
                    id="branchName"
                    inputProps={{
                      placeholder: "Branch Name...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="branchName"
                    value={branchName}
                    onChange={(e) => {
                      setBranchName(e.target.value);
                      setBranchNameErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{branchNameErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    id="firstName"
                    inputProps={{
                      placeholder: "First Name ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="firstName"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameErr("");
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {firstNameErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="lastName"
                    inputProps={{
                      placeholder: "Last Name ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="lastName"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameErr("");
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {lastNameErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label>Mobile Number</label>
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "95%",
                      border: "none",
                      borderBottom: "1px solid #040000",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="mobile"
                    value={mobile}
                    onChange={(mobile) => {
                      let country_code = document.body.querySelector("div.flag")
                        ?.className;
                      country_code =
                        country_code && country_code.replace("flag", "");
                      setCountryCode(country_code.trim());
                      // console.log(country_code);
                      handleMobileNumber(mobile);
                      let country_name = document.body.querySelector(
                        "div.selected-flag"
                      )?.title;
                      setCountry(country_name.slice(0, -6));
                    }}
                    // disabled={true}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {mobileErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label>WhatsApp Number</label>
                  <PhoneInput
                    style={mystyle}
                    inputStyle={{
                      width: "95%",
                      border: "none",
                      borderBottom: "1px solid #040000 ",
                      borderRadius: "0",
                    }}
                    country={"in"}
                    name="whatsApp"
                    value={whatsApp}
                    onChange={(whatsApp) => {
                      setWhatApp(whatsApp);
                    }}
                    // required={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Aadhar Number"
                    id="aadharNumber"
                    inputProps={{
                      placeholder: "Aadhar Number ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="aadharNumber"
                    value={aadharNumber}
                    onChange={(e) => setAadharNumber(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Pan Number"
                    id="panNumber"
                    inputProps={{
                      placeholder: "Pan Number ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="panNumber"
                    value={panNumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="GSTIN Number"
                    id="gstIn"
                    inputProps={{
                      placeholder: "GSTIN Number ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="gstIn"
                    value={gstIn}
                    onChange={handleGST}
                    // onChange={(e) => setGstIn(e.target.value)}
                  />
                  <span style={{ color: "red" }}>{gstInErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Company"
                    id="company"
                    inputProps={{
                      placeholder: "Company ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Alias Name"
                    id="aliasName"
                    inputProps={{
                      placeholder: "Alias Name ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="aliasName"
                    value={aliasName}
                    onChange={(e) => setAliasName(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Plot House No."
                    id="plotHouseNo"
                    inputProps={{
                      placeholder: "Plot, House No., Building ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="plotHouseNo"
                    value={plotHouseNo}
                    onChange={(e) => setPlotHouseNo(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Area Colony"
                    id="areaColony"
                    inputProps={{
                      placeholder: "Area Colony Street Vilage ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="areaColony"
                    value={areaColony}
                    onChange={(e) => {
                      setAreaColony(e.target.value);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Land Mark"
                    id="landMark"
                    inputProps={{
                      placeholder: "LandMark e.g. Near In-Orbit Mall ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="landMark"
                    value={landMark}
                    onChange={(e) => setLandMark(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Road"
                    id="road"
                    inputProps={{ placeholder: "Road ..." }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="road"
                    value={road}
                    onChange={(e) => setRoad(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Zip Code"
                    id="zipCode"
                    inputProps={{ placeholder: "Zip Code ..." }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="zipCode"
                    value={zipCode}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                      setZipCodeErr("");
                    }}
                  />
                  <span style={{ color: "red", marginLeft: "12px" }}>
                    {zipCodeErr}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select State...{state && state.state}
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={stateSelect}
                      onChange={handleState}
                      required={true}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select State
                      </MenuItem>
                      {getStates(countryCode).map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          onClick={() => {
                            setStateCode(item?.state_code);
                          }}
                          classes={{
                            root: classDrop.selectMenuItem,
                            selected: classDrop.selectMenuItemSelected,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{stateSelectErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className={classDrop.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classDrop.selectLabel}
                    >
                      Select City...{state && state.city}
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classDrop.selectMenu,
                      }}
                      classes={{
                        select: classDrop.select,
                      }}
                      value={citySelect}
                      onChange={handleCity}
                      required={true}
                      inputProps={{
                        name: "stateSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classDrop.selectMenuItem,
                        }}
                      >
                        Select City
                      </MenuItem>
                      {getCities(countryCode, stateCode).map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          classes={{
                            root: classDrop.selectMenuItem,
                            selected: classDrop.selectMenuItemSelected,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{citySelectErr}</span>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Country"
                    id="country"
                    inputProps={{ placeholder: "Country ..." }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled={true}
                    name="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}></GridItem>
                <FormControlLabel
                  classes={{
                    root: classCheck.checkboxLabelControl,
                    label: classCheck.checkboxLabel,
                  }}
                  control={
                    <Checkbox
                      id="active"
                      value={active}
                      checked={active & (active === true) ? active : active}
                      onChange={(e) => {
                        setActive(e.target.checked);
                      }}
                      tabIndex={-1}
                      checkedIcon={<Check className={classCheck.checkedIcon} />}
                      icon={<Check className={classCheck.uncheckedIcon} />}
                      classes={{
                        checked: classCheck.checked,
                        root: classCheck.checkRoot,
                      }}
                    />
                  }
                  label={
                    <span style={{ color: "#9c27b0" }}>
                      I would like to recieve the latest news from the Company
                      by phone/whatsapp
                    </span>
                  }
                />
              </GridContainer>
              <br />
              <div className={classes.center}>
                <Button
                  color="rose"
                  onClick={SubmitUpdateBranchTransport}
                  className={classes.updateProfileButton}
                >
                  save
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
