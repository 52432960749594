import React, { useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Close from "@material-ui/icons/Close";
import { ImMobile } from "react-icons/im";
import Table from "components/Table/Table.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { localApi } from "utils/imgApi";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import stylesPrint from "assets/jss/material-dashboard-pro-react/views/crmUserListPrintStyle";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyleDailog = makeStyles(styleDailog);
const useStylePrint = makeStyles(stylesPrint);
const useSty = makeStyles(styCheckbox);
const useStyle = makeStyles(styDrop);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function CallListReceipt() {
  const [dataOrders, setDataOrder] = React.useState([]);
  const [noticeModal, setNoticeModal] = React.useState(false);
  const [callListId, setCallListId] = React.useState("");
  const [callListName, setCallListName] = React.useState("");
  const [callListRemark, setCallListRemark] = React.useState("");
  const [callListSuggest, setCallListSuggest] = React.useState("");
  const [callReferList, setCallReferList] = React.useState([]);

  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makChecked, setMakChecked] = React.useState("");

  const [FromDate, setFromDate] = React.useState("");
  const [ToDate, setToDate] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const classCheck = useSty();
  const classTable = useStylePrint();
  const classeDailog = useStyleDailog();
  const history = useHistory();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const handleFromDatePicker = (FromDate) => {
    setFromDate(FromDate);
  };

  const handleToDatePicker = (ToDate) => {
    setToDate(ToDate);
  };

  const handleCheckBoxMaking = (event, or) => {
    // setCheckBoxmaking(e.target.checked);
    // const { checked } = event.target;

    setCheckBoxmaking((prev) => {
      if (prev.includes(or)) {
        return prev.filter((x) => x !== or);
      } else {
        return [...prev, or];
      }
    });

    setMakChecked((prev) => {
      if (prev.includes(or?.id)) {
        return prev.filter((x) => x !== or?.id);
      } else {
        return [...prev, or?.id];
      }
    });
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleSearchDate = async () => {
    // console.log("From", formatDate(FromDate._d));
    // console.log("To", formatDate(ToDate._d));
    var tempData = {};
    const api =
      localApi +
      `/callreceipt/list/byCrmReferenceIdBetweenDate/${userObj.id}/${formatDate(
        FromDate._d
      )}/${formatDate(ToDate._d)}`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult);

    const callId =
      getResult &&
      getResult.map((i) => {
        return i.id;
      });

    // console.log(callId);

    Promise.all(
      callId.map(async (id) => {
        const usedApi = await fetch(
          localApi +
            `/callreceipt/list/byCrmReferenceIdAndCallreferenceId/${userObj.id}/${id}`
        );
        const data = await usedApi.json();
        // console.log(data);
        tempData = { ...tempData, [id]: data };
      })
    ).then(() => {
      // console.log(tempData);
      const tempItems2 = getResult.map((i) => {
        return { ...i, u: tempData[i.id] || 0 };
      });
      // console.log(tempItems2);
      setDataOrder(
        tempItems2 &&
          tempItems2.map((or, key) => {
            return {
              sino: key + 1,
              dateTime: or.currentdate,
              company: or.company,
              state: or.state,
              mobile: or.mobile,
              call: or.callStatus,
              subCall: or.callSubStatus,
              action: or.action,
              aDate: or.afterdate,
              aTime: or.aftertime,
              actions: (
                <div className="actions-right">
                  <FormControlLabel
                    key={or.id}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        // checked={checkBoxMaking.includes(or)}
                        name={or}
                        onChange={(e) => {
                          handleCheckBoxMaking(e, or);
                        }}
                        checkedIcon={
                          <Check className={classCheck.checkedIcon} />
                        }
                        icon={<Check className={classCheck.uncheckedIcon} />}
                        classes={{
                          checked: classCheck.checkedRose,
                          root: classCheck.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label:
                        classCheck.label +
                        (makingCheckboxMaking === "error"
                          ? " " + classCheck.labelError
                          : ""),
                    }}
                    // label={making.description + "> " + making.price}
                  />
                  {/* {or.balance === 0 ? <span style={{color: "green"}}>Paid</span> : <span style={{color: "red"}}>Pending</span>} */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      setNoticeModal(true);
                      setCallListId(or.id);
                      setCallListName(or.firstname + " " + or.lastname);
                      setCallListRemark(or.callReceiptRemark);
                      setCallListSuggest(or.suggestedActionRemark);
                    }}
                    color="danger"
                    className="remove"
                  >
                    <VisibilityIcon />
                  </Button>{" "}
                </div>
              ),
            };
          })
      );
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Call Receipt List</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={6} sm={6} md={4}>
                <InputLabel className={classe.label}>From Date</InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                    inputProps={{
                      placeholder: "From Date Picker Here",
                    }}
                    onChange={(FromDate) => handleFromDatePicker(FromDate)}
                    name="FromDate"
                    value={FromDate}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={4}>
                <InputLabel className={classe.label}>To Date</InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                    inputProps={{
                      placeholder: "To Picker Here",
                    }}
                    onChange={(ToDate) => handleToDatePicker(ToDate)}
                    name="ToDate"
                    value={ToDate}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              <Button color="rose" round onClick={handleSearchDate}>
                Search
              </Button>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      {dataOrders.length != "0" ? (
        <>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <ReactTable
                  columns={[
                    {
                      Header: "SI.No.",
                      accessor: "sino",
                    },
                    {
                      Header: "DateTime",
                      accessor: "dateTime",
                    },
                    {
                      Header: "Company",
                      accessor: "company",
                    },
                    {
                      Header: "State",
                      accessor: "state",
                    },
                    {
                      Header: "Mobile",
                      accessor: "mobile",
                    },
                    {
                      Header: "Call",
                      accessor: "call",
                    },
                    {
                      Header: "SubCall",
                      accessor: "subCall",
                    },
                    {
                      Header: "Action",
                      accessor: "action",
                    },
                    {
                      Header: "A Date",
                      accessor: "aDate",
                    },
                    {
                      Header: "A Time",
                      accessor: "aTime",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                    },
                  ]}
                  data={dataOrders}
                />
              </CardBody>
            </Card>
          </GridItem>
          <div>
            <Button
              color="rose"
              round
              // href={`/view-crm-print?city=adnoi&firstname=saleem&createDate=2023-03-17`}
              onClick={() =>
                history.push({
                  pathname: `/view-calling-print`,
                  state: checkBoxMaking,
                })
              }
            >
              print
            </Button>
          </div>{" "}
        </>
      ) : (
        ""
      )}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} className={classeDailog.center}>
          <Dialog
            classes={{
              root: classeDailog.center + " " + classeDailog.modalRoot,
              paper: classeDailog.modal,
            }}
            open={noticeModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setNoticeModal(false)}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classeDailog.modalHeader}
            >
              <Button
                justIcon
                className={classeDailog.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setNoticeModal(false)}
              >
                <Close className={classeDailog.modalClose} />
              </Button>
            </DialogTitle>
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classeDailog.modalHeader}
            >
              <h4
                className={classeDailog.modalTitle}
                style={{ fontWeight: "bold" }}
              >
                Complain No.: {callListId}
              </h4>
              <h4
                className={classeDailog.modalTitle}
                style={{ fontWeight: "bold" }}
              >
                Contact Name: {callListName}
              </h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classeDailog.modalBody}
            >
              <GridContainer
                style={{ marginLeft: "0px", marginRight: "400px" }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <span
                    style={{ textDecoration: "underline" }}
                    className={classeDailog.paymentOrder}
                  >
                    Call Receipt Remarks
                  </span>
                  <br />
                  <span className={classeDailog.callDesc}>
                    {callListRemark}
                  </span>
                </GridItem>
              </GridContainer>
              <hr />
              <GridContainer
                style={{ marginLeft: "0px", marginRight: "400px" }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <span
                    style={{ textDecoration: "underline" }}
                    className={classeDailog.paymentOrder}
                  >
                    Suggested Action Remaks
                  </span>
                  <br />
                  <span className={classeDailog.callDesc}>
                    {callListSuggest}
                  </span>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions
              className={
                classeDailog.modalFooter + " " + classeDailog.modalFooterCenter
              }
            >
              {/* <Button color="rose" round onClick={() => handleOrderUpdate()}>
                  update
                </Button> */}
            </DialogActions>
          </Dialog>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
}
