import React, { useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { localApi, credentials } from "utils/imgApi";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";

import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyleDailog = makeStyles(styleDailog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function OrderList() {
  const [dataOrders, setDataOrder] = React.useState([]);
  const [noticeModal, setNoticeModal] = React.useState(false);

  const [orderID, setOrderId] = React.useState("");
  const [orderPayment, setOrderPayment] = React.useState("");
  const [orderdate, setOrderDate] = React.useState("");
  const [orderAmount, setOrderAmount] = React.useState("");

  const classes = useStyles();
  const classeDailog = useStyleDailog();
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getSets() {
    const api = localApi + `/orders/list/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setDataOrder(
      getResult.map((or, key) => {
        return {
          sino: key + 1,
          orderId: or.id,
          company: or.company,
          date: or.createDate.slice(0, -19),
          boxesDozan: or.totalBox + " - " + "Units",
          amount: "₹" + Math.round(or.amount) + "/-",
          actions: (
            <div className="actions-right">
              {or.balance === 0 ? <span style={{color: "green"}}>Paid</span> : <span style={{color: "red"}}>Pending</span>}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setNoticeModal(true);
                  setOrderId(or.id);
                  setOrderPayment(or.payment);
                }}
                color="danger"
                className="remove"
              >
                <VisibilityIcon />
              </Button>{" "}
            </div>
          ),
        };
      })
    );
  }

  useEffect(() => {
    getSets();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Order List</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "SI.No.",
                  accessor: "sino",
                },
                {
                  Header: "OrderId",
                  accessor: "orderId",
                },
                {
                  Header: "Company",
                  accessor: "company",
                },
                {
                  Header: "Date",
                  accessor: "date",
                },
                {
                  Header: "Boxes/Dozan",
                  accessor: "boxesDozan",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              data={dataOrders}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} className={classeDailog.center}>
          <Dialog
            classes={{
              root: classeDailog.center + " " + classeDailog.modalRoot,
              paper: classeDailog.modal,
            }}
            open={noticeModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setNoticeModal(false)}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classeDailog.modalHeader}
            >
              <Button
                justIcon
                className={classeDailog.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setNoticeModal(false)}
              >
                <Close className={classeDailog.modalClose} />
              </Button>
              <h4 className={classeDailog.modalTitle} style={{fontWeight: "bold"}}>OrderId: {orderID}</h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classeDailog.modalBody}
            >
              <GridContainer
                style={{ marginLeft: "0px", marginRight: "400px" }}
              >
                <GridItem xs={3} sm={3} md={3}>
                  <span className={classeDailog.paymentOrder}>Si No</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <span className={classeDailog.paymentOrder}>OrderId</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <span className={classeDailog.paymentOrder}>Date</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <span className={classeDailog.paymentOrder}>Amount</span>
                </GridItem>
              </GridContainer>
              <hr />
              {noticeModal === true ? orderPayment.map((op, index) => 
              <GridContainer
                style={{ marginLeft: "0px", marginRight: "400px" }}
                key={op.id}
              >
                <GridItem xs={3} sm={3} md={3}>
                  <span>{index + 1}</span>
                </GridItem><hr />
                <GridItem xs={3} sm={3} md={3}>
                  <span>{op.id}</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <span>{op.createDate.slice(0, -19)}</span>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <span>{op.received}</span>
                </GridItem>
              </GridContainer>) : "" }
              <hr />
            </DialogContent>
            <DialogActions
              className={
                classeDailog.modalFooter + " " + classeDailog.modalFooterCenter
              }
            >
              {/* <Button color="rose" round onClick={() => handleOrderUpdate()}>
                  update
                </Button> */}
            </DialogActions>
          </Dialog>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
}
