import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { localApi } from "utils/imgApi";
import { produce } from "immer";
import axios from "axios";
import Selects from "react-select";
import Table from "components/Table/Tables.js";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import stylesTable from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleCheck = makeStyles(styleCheck);
const useStyl = makeStyles(styDrop);
const useStyleTable = makeStyles(stylesTable);

export default function CallListReport() {
  const [itemClient, setItemClient] = React.useState([]);
  const [callSub, setCallSub] = React.useState([]);
  const [simpleSelectExecutive, setSimpleSelectExecutive] = React.useState("");
  const [simpleSelectAllocation, setSimpleSelectAllocation] = React.useState(
    ""
  );
  const [contactDetails, setContactDetails] = React.useState("");
  const [simpleSelectCallStatus, setSimpleSelectCallStatus] = React.useState(
    ""
  );
  const [simpleSelectCategory, setSimpleSelectCategory] = React.useState("");
  const [
    simpleSelectCallSubStatus,
    setSimpleSelectCallSubStatus,
  ] = React.useState("");
  const [simpleSelectNextAction, setSimpleSelectNextAction] = React.useState(
    ""
  );
  const [AfterDate, setAfterDate] = React.useState("");
  const [AfterTime, setAfterTime] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);

  const [expandedRows, setExpandedRows] = React.useState([]);
  const [expandState, setExpandState] = React.useState({});

  const classes = useStyles();
  const classSweet = useStyle();
  const classCheck = useStyleCheck();
  const classe = useStyl();
  const classTable = useStyleTable();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function formatTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  async function getCrmData() {
    const api = localApi + `/register/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(api);
    const getResult = await results.json();
    setItemClient(getResult.filter((r) => r.type === "Crm"));
  }

  useEffect(() => {
    getCrmData();
  }, []);

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };

  const handleSimpleCallStatus = (e) => {
    setSimpleSelectCallStatus(e.target.value);
  };

  const handleSimpleCallSubStatus = async (e) => {
    // setSimpleSelectCallSubStatus(e.target.value);
    const { value } = e.target;
    setSimpleSelectCallSubStatus(value);
    var tempData = {};
    const api =
      localApi +
      `/callreceipt/byCallStatusAndCallSubStatus/${simpleSelectCallStatus}/${value}`;
    const result = await fetch(api);
    const getResult = await result.json();
    // setCallSub(getResult);
    // console.log(getResult).filter((f) => f.allocation === true);

    const callId =
      getResult &&
      getResult.map((i) => {
        return i.id;
      });

    // console.log(callId);

    Promise.all(
      callId.map(async (id) => {
        const usedApi = await fetch(
          localApi + `/callallocation/byCallReference/${id}`
        );
        const data = await usedApi.json();
        // console.log(data);
        tempData = { ...tempData, [id]: data };
      })
    ).then(() => {
      //   console.log(tempData);
      const tempItems2 = getResult.map((i) => {
        return { ...i, u: tempData[i.id] || 0 };
      });
      setCallSub(tempItems2);
      console.log(tempItems2);
    });
  };

  const openAPi = [
    { id: 1, subStatus: "ACTIVE" },
    { id: 2, subStatus: "HOLD" },
    { id: 3, subStatus: "PENDING" },
    { id: 4, subStatus: "UNDER PROCESS" },
    { id: 5, subStatus: "PAYMENT" },
  ];

  const closeApi = [
    { id: 1, subStatus: "CANCLED" },
    { id: 2, subStatus: "SUCCESSFUL" },
    { id: 3, subStatus: "UNSUCCESSFUL" },
  ];

  const handleClick = () => {};

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PersonPinCircleIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Call List Report</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Call Status...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCallStatus}
                        onChange={handleSimpleCallStatus}
                        inputProps={{
                          name: "stateSelectCallStatus",
                          id: "simple-select-call-status",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Call Status
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classe.selectMenuItem,
                            selected: classe.selectMenuItemSelected,
                          }}
                          value="OPEN"
                        >
                          OPEN
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classe.selectMenuItem,
                            selected: classe.selectMenuItemSelected,
                          }}
                          value="CLOSED"
                        >
                          Closed
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Call Sub Status...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCallSubStatus}
                        onChange={handleSimpleCallSubStatus}
                        inputProps={{
                          name: "stateSelectCallSubStatus",
                          id: "simple-select-call-sub-status",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Call Sub Status
                        </MenuItem>
                        {simpleSelectCallStatus
                          ? simpleSelectCallStatus === "OPEN"
                            ? openAPi.map((o) => (
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  key={o.id}
                                  value={o.subStatus}
                                >
                                  {o.subStatus}
                                </MenuItem>
                              ))
                            : closeApi.map((c) => (
                                <MenuItem
                                  classes={{
                                    root: classe.selectMenuItem,
                                    selected: classe.selectMenuItemSelected,
                                  }}
                                  key={c.id}
                                  value={c.subStatus}
                                >
                                  {c.subStatus}
                                </MenuItem>
                              ))
                          : ""}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {callSub.length != "0" ?
      <GridContainer>
        {simpleSelectCallSubStatus ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div className={classTable.container}>
                <h3 className={classTable.cardTitle}>Call Allocation Report</h3>
                <Table
                  hover
                  tableHead={[
                    <span className={classTable.tableHead} key="sr">
                      Sr.No.
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headLeft
                      }
                      key="dateTime"
                    >
                      DateTime
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headLeft
                      }
                      key="contact"
                    >
                      Contact
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headLeft
                      }
                      key="executive"
                    >
                      Executive
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headLeft
                      }
                      key="call"
                    >
                      CAll
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headLeft
                      }
                      key="subCall"
                    >
                      SubCall
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headLeft
                      }
                      key="action"
                    >
                      Action
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headLeft
                      }
                      key="aDate"
                    >
                      A Date
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headLeft
                      }
                      key="aTime"
                    >
                      A Time
                    </span>,
                  ]}
                  tableData={
                    callSub &&
                    callSub.map((c, index) => [
                      [
                        <span className={classTable.OrderlistDescription}>
                          {/* {index + 1} */}
                          {c.id}
                        </span>,
                        <span
                          className={
                            classTable.headLeft + " " + classTable.listNumber
                          }
                        >
                          {c.createdate}
                        </span>,
                        <span
                          className={
                            classTable.headLeft + " " + classTable.listNumber
                          }
                        >
                          {c.firstname + " " + c.lastname}
                        </span>,
                        <span
                          className={
                            classTable.headLeft + " " + classTable.listNumber
                          }
                        >
                          {c.receivedBy}
                        </span>,
                        <span
                          className={
                            classTable.headLeft + " " + classTable.listNumber
                          }
                        >
                          {c.callStatus}
                        </span>,
                        <span
                          className={
                            classTable.headLeft + " " + classTable.listNumber
                          }
                        >
                          {c.callSubStatus}
                        </span>,
                        <span
                          className={
                            classTable.headLeft + " " + classTable.listNumber
                          }
                        >
                          {c.action}
                        </span>,
                        <span
                          className={
                            classTable.headLeft + " " + classTable.listNumber
                          }
                        >
                          {c.afterdate}
                        </span>,
                        <span
                          className={
                            classTable.headLeft + " " + classTable.listNumber
                          }
                        >
                          {c.aftertime}
                        </span>,
                        <span>
                          {c.u.length !== 0 ? (
                            <Button
                              link
                              className={
                                classTable.headLeft +
                                " " +
                                classTable.actionButton
                              }
                              onClick={(event) => handleEpandRow(event, c.id)}
                            >
                              {expandState[c.id] ? (
                                <ArrowUpwardIcon style={{ color: "red" }} />
                              ) : (
                                <ArrowDownwardIcon style={{ color: "red" }} />
                              )}
                            </Button>
                          ) : (
                            ""
                          )}
                        </span>,
                      ],
                      [
                        expandedRows.includes(c.id) ? (
                          <span>
                            {c.u.map((p, index) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {/* {index + 1} */}
                                {p.id}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span
                            className={
                              classTable.headLeft + " " + classTable.subList
                            }
                          >
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {p.createdate}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span
                            className={
                              classTable.headLeft + " " + classTable.subList
                            }
                          >
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {p.firstname + " " + p.lastname}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span
                            className={
                              classTable.headLeft + " " + classTable.subList
                            }
                          >
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {p.receivedBy}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span
                            className={
                              classTable.headLeft + " " + classTable.subList
                            }
                          >
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {p.callStatus}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span
                            className={
                              classTable.headLeft + " " + classTable.subList
                            }
                          >
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {p.callSubStatus}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span
                            className={
                              classTable.headLeft + " " + classTable.subList
                            }
                          >
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {p.action}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span
                            className={
                              classTable.headLeft + " " + classTable.subList
                            }
                          >
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {p.afterdate}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        expandedRows.includes(c.id) ? (
                          <span
                            className={
                              classTable.headLeft + " " + classTable.subList
                            }
                          >
                            {c.u.map((p) => (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  lineHeight: "20px",
                                }}
                                key={p.id}
                              >
                                {p.aftertime}
                                <br />
                              </span>
                            ))}
                          </span>
                        ) : null,
                        "",
                      ],
                    ])
                  }
                />
              </div>
            </Card>
          </GridItem>
        ) : (
          ""
        )}
      </GridContainer> : "" }
    </div>
  );
}
