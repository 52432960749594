import React, { useEffect } from "react";
import axios from "axios";
import moment from "moment";

// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { headApi } from "utils/headApi";
// import { imgApi } from "utils/imgApi";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import GroupIcon from "@material-ui/icons/Group";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { localApi } from "utils/imgApi";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/card-3.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";

// import b1 from "assets/img/b1.jpg";
// import b2 from "assets/img/b2.jpg";
// import b3 from "assets/img/b3.jpg";
// import b4 from "assets/img/b4.jpg";

// const us_flag = require("assets/img/flags/US.png");
// const de_flag = require("assets/img/flags/DE.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");
// const ro_flag = require("assets/img/flags/RO.png");
// const br_flag = require("assets/img/flags/BR.png");

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 356,
  RO: 600,
  RU: 300,
  US: 2920,
};

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [countryTable, setCountryTable] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [orderCount, setOrderCount] = React.useState([]);
  const [cartToday, setCartToday] = React.useState([]);
  const [userCount, setUserCount] = React.useState("");
  const [cMap, setCMap] = React.useState("");
  const [follwerCount, setFollwerCount] = React.useState("");
  const [graphComplet, setGraphComplet] = React.useState([]);
  const [graphCompletLastSeen, setGraphCompletLastSeen] = React.useState("");
  const [graphSales, setGraphSales] = React.useState([]);
  const [graphSaleLastSeen, setGraphSaleLastSeen] = React.useState("");
  const [graphSalesPerCurr, setGraphSalesPerCurr] = React.useState("");
  const [graphSalesPerYes, setGraphSalesPerYes] = React.useState("");

  const classes = useStyles();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));
  // const headApi = `https://api.bangles.org.in/api/rest`;
  // http://localhost:8085/api/cart/pendingCart/byAdminIdAndGroupClientAndAcitveZeroAndCount/42?active=0

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  async function getOrderCard() {
    const api = localApi + `/ordersCart/list/byAdminId/2`;
    const result = await fetch(api);
    const getResult = await result.json();
    setOrderList(getResult);
    // console.log(getResult);
  }

  async function getOrderCount() {
    const api =
      localApi +
      `/cart/pendingCart/byAdminIdAndGroupClientAndAcitveZeroAndCount/2?active=0`;
    const result = await fetch(api);
    const getResult = await result.json();
    setOrderCount(getResult);
  }

  async function fetchRegCount() {
    const api = localApi + `/register/list/byAdminIdAndCount/2`;
    const result = await fetch(api);
    const getResult = await result.json();
    setUserCount(getResult);
  }

  // async function fetchData() {
  //   var tempData = {};
  //   const api = localApi + `/register/list/byAdminIdAndCountryList/2`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();

  //   const countryKey = getResult && getResult.map((p) => {
  //     return p.countryCode;
  //   });
  //   // console.log(countryKey);

  //   const countryValue = getResult && getResult.map((p) => {
  //     return p.isoNumber;
  //   });
  //   // console.log(countryValue);

  //   const resultMap = {};
  //   countryValue.forEach((value, i) => (resultMap[countryKey[i]] = value));
  //   setCMap(resultMap);
  //   // console.log(resultMap);

  //   const countryCount = getResult && getResult.map((p) => {
  //     return p.country;
  //   });
  //   Promise.all(
  //     countryCount && countryCount.map(async (country) => {
  //       const usedApi = await fetch(
  //         localApi + `/register/list/byAdminIdAndCountry/2/${country}`
  //       );
  //       const data = await usedApi.json();
  //       // console.log(data);
  //       tempData = { ...tempData, [country]: data };
  //     })
  //   ).then(() => {
  //     // console.log(tempData);
  //     const tempItems2 = getResult.map((i) => {
  //       return { ...i, u: tempData[i.country] || 0 };
  //     });
  //     // console.log(tempItems2);
  //     setCountryTable(tempItems2 && tempItems2);
  //   });
  // }

  async function getCartToday() {
    const api =
      localApi + `/cart/todayCart/byAdminIdAndActiveZeroAndCurrentDate/2`;
    const result = await fetch(api);
    const getResult = await result.json();
    setCartToday(getResult);
  }

  async function getFollower() {
    const api = localApi + `/visitor/list/byFollowId/2`;
    const result = await fetch(api);
    const getResult = await result.json();
    const mainListCount = getResult.length;
    setFollwerCount(mainListCount);
  }

  // async function getChartsSales() {
  //   var tempAmt = {};
  //   const api = localApi + `/orders/list/byAdminIdwithInvoice/2`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   // console.log(getResult);
  //   const getSort =
  //     getResult.length != "0"
  //       ? getResult.sort((a, b) => {
  //           return b.lastUpdate - a.lastUpdate;
  //         })
  //       : "0";
  //   // console.log(getSort);
  //   setGraphSaleLastSeen(getSort[0].lastDays);

  //   const gOnlyDate = getResult.map((o) => {
  //     return o.onlyDate;
  //   });

  //   // console.log(gOnlyDate);
  //   Promise.all(
  //     gOnlyDate.map(async (onlyDate) => {
  //       const response = await fetch(
  //         localApi +
  //           `/orders/list/byAdminIdwithInvoiceAndOnlyDate/2/${onlyDate}`
  //       );
  //       const data = await response.json();
  //       tempAmt = { ...tempAmt, [onlyDate]: data };
  //       // console.log(data);
  //     })
  //   ).then(() => {
  //     // console.log(tempAmt);
  //     const tempItems2 = getResult.map((i) => {
  //       return { ...i, u: tempAmt[i.onlyDate] || 0 };
  //     });
  //     // console.log(tempItems2);

  //     const gAmount = tempItems2.map((a) => {
  //       return a.u / 1000;
  //     });
  //     // console.log(gAmount);

  //     const gDay = tempItems2.map((a) => {
  //       return a.dayMonth;
  //     });
  //     // console.log(gDay);

  //     var Chartist = require("chartist");
  //     const dailySalesChart = {
  //       data: {
  //         labels: gDay,
  //         series: [gAmount],
  //       },
  //       options: {
  //         lineSmooth: Chartist.Interpolation.cardinal({
  //           tension: 0,
  //         }),
  //         low: 0,
  //         high: 1000000 / 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
  //         chartPadding: {
  //           top: 13,
  //           right: 0,
  //           bottom: 0,
  //           left: 0,
  //         },
  //       },
  //     };
  //     // console.log(dailySalesChart);
  //     setGraphSales(dailySalesChart);
  //   });
  // }

  // async function getGraphSalesPer() {
  //   let currentDate = moment().format("YYYY-MM-DD");
  //   let yesterdaydate = moment(currentDate)
  //     .subtract(1, "days")
  //     .format("YYYY-MM-DD");
  //   // console.log(currentDate);
  //   // console.log(yesterdaydate);

  //   const apiCurrent =
  //     localApi +
  //     `/orders/list/byAdminIdwithInvoiceAndOnlyDate/2/${currentDate}`;
  //   const resultCurrent = await fetch(apiCurrent);
  //   const getResultCurrent = await resultCurrent.json();
  //   // console.log(getResult);
  //   setGraphSalesPerCurr(getResultCurrent);

  //   const apiYesterday =
  //     localApi +
  //     `/orders/list/byAdminIdwithInvoiceAndOnlyDate/2/${yesterdaydate}`;
  //   const resultYesterday = await fetch(apiYesterday);
  //   const getResultYesterday = await resultYesterday.json();
  //   // console.log(getResultYesterday);
  //   setGraphSalesPerYes(getResultYesterday);
  // }

  // async function getChartsComplet() {
  //   var tempAmt = {};
  //   const api = localApi + `/orders/list/byAdminIdwithBalanceZero/2`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   // console.log(getResult);
  //   const getSort =
  //     getResult.length != "0"
  //       ? getResult.sort((a, b) => {
  //           return b.lastUpdate - a.lastUpdate;
  //         })
  //       : "0";
  //   setGraphCompletLastSeen(getSort[0].lastDays);

  //   const gOnlyDate = getResult.map((o) => {
  //     return o.onlyDate;
  //   });

  //   // console.log(gOnlyDate);
  //   Promise.all(
  //     gOnlyDate.map(async (onlyDate) => {
  //       const response = await fetch(
  //         localApi +
  //           `/orders/list/byAdminIdwithBalanceZeroAndOnlyDate/2/${onlyDate}`
  //       );
  //       const data = await response.json();
  //       tempAmt = { ...tempAmt, [onlyDate]: data };
  //       // console.log(data);
  //     })
  //   ).then(() => {
  //     // console.log(tempAmt);
  //     const tempItems2 = getResult.map((i) => {
  //       return { ...i, u: tempAmt[i.onlyDate] || 0 };
  //     });
  //     // console.log(tempItems2);

  //     const gAmount = tempItems2.map((a) => {
  //       return a.u / 1000;
  //     });
  //     // console.log(gAmount);

  //     const gDay = tempItems2.map((a) => {
  //       return a.dayMonth;
  //     });
  //     // console.log(gDay);

  //     var Chartist = require("chartist");
  //     const dailySalesChart = {
  //       data: {
  //         labels: gDay,
  //         series: [gAmount],
  //       },
  //       options: {
  //         lineSmooth: Chartist.Interpolation.cardinal({
  //           tension: 0,
  //         }),
  //         low: 0,
  //         high: 1000000 / 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
  //         chartPadding: {
  //           top: 13,
  //           right: 0,
  //           bottom: 0,
  //           left: 0,
  //         },
  //       },
  //     };
  //     // console.log(dailySalesChart);
  //     setGraphComplet(dailySalesChart);
  //   });
  // }

  useEffect(() => {
    fetchRegCount();
    // fetchData();
    getOrderCard();
    getOrderCount();
    getCartToday();
    getFollower();
    // getChartsComplet();
    // getChartsSales();
    // getGraphSalesPer();
  }, []);

  const myComponent = {
    width: "100%",
    height: "300px",
    overflowX: "hidden",
    overflowY: "scroll",
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                {/* <Icon>content_copy</Icon> */}
                <GroupIcon />
              </CardIcon>

              <p className={classes.cardCategory}>User Count</p>

              {/* <h4 className={classes.cardTitle}>{userCount}</h4> */}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                <a href="/admin/userList">Last 24 Hours Update</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Revenue</p>
              {/* <h4 className={classes.cardTitle}>
                ₹{" "}
                {Math.round(
                  orderList &&
                    orderList.reduce(
                      (a, b) => a + b.amount + b.cartPackCharge,
                      0
                    )
                ) + "/-"}
              </h4> */}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                <a href="/admin/orderPendingAmount">Last 24 Hours</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Pending Order</p>
              {/* <h4 className={classes.cardTitle}>{orderCount}</h4> */}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                <a href="/admin/cartPending">Pending Cart</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <i className="fab fa-twitter" />
              </CardIcon>
              <p className={classes.cardCategory}>Followers</p>
              {/* <h4 className={classes.cardTitle}>{follwerCount}</h4> */}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                <a href="/admin/follower">Just Updated</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Language />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Global Sales by Top Locations
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={5}>
                  <div style={myComponent}>
                    {/* <Table
                      tableData={
                        countryTable &&
                        countryTable.map((c) => [
                          // <img src={us_flag} alt="us_flag" key={"flag"} />,
                          <span key={c.id}></span>,
                          <span key={c.id}>{c.country}</span>,
                          <span key={c.id}>{c.u}</span>,
                          <span key={c.id}></span>,
                        ])
                      }
                    /> */}
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <VectorMap
                    map={"world_mill"}
                    backgroundColor="transparent"
                    zoomOnScroll={false}
                    containerStyle={{
                      width: "100%",
                      height: "280px",
                    }}
                    containerClassName="map"
                    regionStyle={{
                      initial: {
                        fill: "#e4e4e4",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0,
                      },
                    }}
                    series={{
                      regions: [
                        {
                          // values: cMap,
                          values: "",
                          scale: ["#AAAAAA", "#444444"],
                          normalizeFunction: "polynomial",
                        },
                      ],
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="info" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                // data={graphSales.data}
                data=""
                type="Line"
                // options={graphSales.options}
                options=""
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} />{" "}
                  {/* {((graphSalesPerYes / graphSalesPerCurr) * 100).toFixed(2)}% */}
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 
                {/* {graphSaleLastSeen} */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="warning" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="danger" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                // data={graphComplet.data}
                data=""
                type="Line"
                // options={graphComplet.options}
                options=""
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 
                {/* {graphCompletLastSeen} */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/* {cartToday && cartToday.length === 0 ? "" : <h3>Manage Cart Listings</h3>} */}
      <br />
      <GridContainer>
        {/* {cartToday &&
          cartToday.map((ct) => (
            <GridItem xs={12} sm={12} md={3} key={ct.id}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <img
                    src={
                      ct.cartImage[0].cropImageUrl +
                      "?id=" +
                      ct.cartImage[0].imgId
                    }
                    alt="..."
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardProductTitle}>
                    {ct && ct.company
                      ? ct.company
                      : ct && ct.firstName + " " + ct.lastName}
                  </h4>
                </CardBody>
                <CardFooter product>
                  <div className={classes.price}>
                    <h5>
                      ₹{" "}
                      {ct.gstCash === true
                        ? ct.taxInclude.toFixed(2)
                        : ct.cashPrice.toFixed(2)}
                    </h5>
                  </div>
                  <div className={`${classes.stats} ${classes.productStats}`}>
                    <Place /> {ct.city}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          ))} */}
      </GridContainer>
    </div>
  );
}
