import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import LabourPage from "views/Pages/LabourPage.js";
import PricingPage from "views/Pages/PricingPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import EditProfile from "views/Pages/EditProfile";
import { GiShoppingCart } from "react-icons/gi";

import AddContact from "views/Pages/AddContact.js";
import UpdateContact from "views/Pages/UpdateContact.js";
import UpdateClient from "views/Pages/UpdateClient";
import ViewContactDetails from "views/Pages/ViewContactDetails.js";
import LastUpdatedClient from "views/Pages/LastUpdatedClient.js";
import { HiOutlineUserAdd } from "react-icons/hi";
import ContactList from "views/Pages/ContactList.js";
import ContactMonth from "views/Pages/ContactMonth.js";
import CheckboxList from "views/Pages/ChekboxList";
import CallReceipt from "views/Pages/CallReceipt";
import CallListReceipt from "views/Pages/CallListReceipt"
import CurrentStatus from "views/Pages/CurrentStatus";
import CallAllocation from "views/Pages/CallAllocation";
import CallMultiAllocation from "views/Pages/CallMultiAllocation";
import AllocationReport from "views/Pages/AllocationReport";
import AllocationMultiReport from "views/Pages/AllocationMultiReport";
import CallReport from "views/Pages/CallReport";
import CallListReport from "views/Pages/CallListReport";

import AddTransport from "views/Pages/AddTransport.js";
import AddBranchTransport from "views/Pages/AddBranchTransport.js";
import UpdateBranch from "views/Pages/UpdateBranch.js";
import ViewBranch from "views/Pages/ViewBranch.js";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

import AddCart from "views/Pages/AddCart.js";
import UpdateCart from "views/Pages/UpdateCart.js";
import DeleteCart from "views/Pages/DeleteCart.js";
import ViewCart from "views/Pages/ViewCart.js";
import MyOrder from "views/Pages/MyOrder.js";
import OrderHistory from "views/Pages/OrderHistory.js";
import OrderList from "views/Pages/OrderList.js"
import OrderPendingAmount from "views/Pages/OrderPandingAmount.js"
import CartPending from "views/Pages/CartPending.js"
import ViewPendingCart from "views/Pages/ViewPendingCart.js"
import Follower from "views/Pages/Follower.js"

import Address from "views/Pages/Address.js";
import ViewAddress from "views/Pages/ViewAddress.js";

import AddCropImage from "views/Pages/AddCropImage.js";
import AddImage from "views/Pages/AddImage.js";
import ViewImage from "views/Pages/ViewImage.js";
import UpdateCropImage from "views/Pages/UpdateCropImage.js";
import UpdateImage from "views/Pages/UpdateImage.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";
import PersonIcon from "@material-ui/icons/Person";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },

  {
    collapse: true,
    name: "My Profile",
    rtlName: "صفحات",
    icon: PersonIcon,
    state: "profileCollapse",
    views: [
      {
        path: "/editProfile",
        name: "Edit Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "EP",
        rtlMini: "شع",
        component: EditProfile,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Address",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "addressCollapse",
        views: [
          {
            path: "/address",
            name: "Add Address",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AAd",
            rtlMini: "شع",
            component: Address,
            layout: "/admin",
          },
          {
            path: "/viewAddress",
            name: "View Address",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VA",
            rtlMini: "شع",
            component: ViewAddress,
            layout: "/admin",
          },
        ],
      },
      
    ],
  },
  {
    collapse: true,
    name: "Image",
    rtlName: "صفحات",
    icon: PersonIcon,
    state: "imageCollapse",
    views: [
      {
        path: "/addImage",
        name: "Add Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AI",
        rtlMini: "شع",
        component: AddImage,
        layout: "/admin",
      },
      {
        path: "/addCropImage",
        name: "Add Crop Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "ACI",
        rtlMini: "شع",
        component: AddCropImage,
        layout: "/admin",
      },
      {
        path: "/updateImage",
        name: "Update Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UI",
        rtlMini: "شع",
        component: UpdateImage,
        layout: "/admin",
      },
      {
        path: "/updateCropImage",
        name: "Update Crop Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UCI",
        rtlMini: "شع",
        component: UpdateCropImage,
        layout: "/admin",
      },
      {
        path: "/viewImage",
        name: "View Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VI",
        rtlMini: "شع",
        component: ViewImage,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Enquiry Management",
    rtlName: "صفحات",
    icon: HiOutlineUserAdd,
    state: "enquiryManagementCollapse",
    views: [
      {
        path: "/addContact",
        name: "Add Contact",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AC",
        rtlMini: "شع",
        component: AddContact,
        layout: "/admin",
      },
      {
        path: "/updateContact",
        name: "Update Contact",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UC",
        rtlMini: "شع",
        component: UpdateContact,
        layout: "/admin",
      },
      {
        path: "/contactList",
        name: "Contact List",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CL",
        rtlMini: "شع",
        component: ContactList,
        layout: "/admin",
      },
      {
        path: "/ContactMonth",
        name: "Contact Month",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CM",
        rtlMini: "شع",
        component: ContactMonth,
        layout: "/admin",
      },
      {
        path: "/callReceipt",
        name: "Call Receipt",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CR",
        rtlMini: "شع",
        component: CallReceipt,
        layout: "/admin",
      },
      {
        path: "/updateClient",
        name: "Update Client",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UC",
        rtlMini: "شع",
        component: UpdateClient,
        layout: "/admin",
      },
      {
        path: "/callListReceipt",
        name: "Call List Receipt",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CLR",
        rtlMini: "شع",
        component: CallListReceipt,
        layout: "/admin",
      },
      {
        path: "/callAllocation",
        name: "Call Allocation",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CA",
        rtlMini: "شع",
        component: CallAllocation,
        layout: "/admin",
      },
      {
        path: "/allocationReport",
        name: "Allocation Report",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AR",
        rtlMini: "شع",
        component: AllocationReport,
        layout: "/admin",
      },
      {
        path: "/currentStatus",
        name: "Current Status",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CS",
        rtlMini: "شع",
        component: CurrentStatus,
        layout: "/admin",
      },
      {
        path: "/callMultiAllocation",
        name: "Call Multi Allocation",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CMA",
        rtlMini: "شع",
        component: CallMultiAllocation,
        layout: "/admin",
      },
      {
        path: "/allocationMultiReport",
        name: "Allocation Multi Report",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AMR",
        rtlMini: "شع",
        component: AllocationMultiReport,
        layout: "/admin",
      },
      {
        path: "/callReport",
        name: "Call Report",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CR",
        rtlMini: "شع",
        component: CallReport,
        layout: "/admin",
      },
      {
        path: "/callListReport",
        name: "Call List Report",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CLR",
        rtlMini: "شع",
        component: CallListReport,
        layout: "/admin",
      },
      {
        path: "/viewContactDetails",
        name: "View Contact Details",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VCD",
        rtlMini: "شع",
        component: ViewContactDetails,
        layout: "/admin",
      },
      {
        path: "/lastUpdatedClient",
        name: "Last Updated Client",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "LUC",
        rtlMini: "شع",
        component: LastUpdatedClient,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Cart",
    rtlName: "صفحات",
    icon: GiShoppingCart,
    state: "cartCollapse",
    views: [
      {
        path: "/addCart",
        name: "Add Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AC",
        rtlMini: "شع",
        component: AddCart,
        layout: "/admin",
      },
      {
        path: "/updateCart",
        name: "Update Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UC",
        rtlMini: "شع",
        component: UpdateCart,
        layout: "/admin",
      },
      {
        path: "/deleteCart",
        name: "Delete Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "DC",
        rtlMini: "شع",
        component: DeleteCart,
        layout: "/admin",
      },
      {
        path: "/viewCart",
        name: "View Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VC",
        rtlMini: "شع",
        component: ViewCart,
        layout: "/admin",
      },
      {
        path: "/myOrder",
        name: "My Order",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "MO",
        rtlMini: "شع",
        component: MyOrder,
        layout: "/admin",
      },
      {
        path: "/orderHistory",
        name: "Order History",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "OH",
        rtlMini: "شع",
        component: OrderHistory,
        layout: "/admin",
      },
      {
        path: "/orderList",
        name: "Order List",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "OL",
        rtlMini: "شع",
        component: OrderList,
        layout: "/admin",
      },
      {
        path: "/orderPendingAmount",
        name: "Order Pending Amount",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "OPA",
        rtlMini: "شع",
        component: OrderPendingAmount,
        layout: "/admin",
      },
      {
        path: "/cartPending",
        name: "Cart Pending",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CP",
        rtlMini: "شع",
        component: CartPending,
        layout: "/admin",
      },
      {
        path: "/viewPendingCart",
        name: "View Pending Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VPC",
        rtlMini: "شع",
        component: ViewPendingCart,
        layout: "/admin",
      },
      {
        path: "/follower",
        name: "Follower",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "F",
        rtlMini: "شع",
        component: Follower,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Transport",
    rtlName: "صفحات",
    icon: LocalShippingIcon,
    state: "transportCollapse",
    views: [
      {
        path: "/addTransport",
        name: "Add Transport",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AT",
        rtlMini: "شع",
        component: AddTransport,
        layout: "/admin",
      },
      {
        path: "/addBranchTransport",
        name: "Add Branch Transport",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "ABT",
        rtlMini: "شع",
        component: AddBranchTransport,
        layout: "/admin",
      },
      {
        path: "/updateBranch",
        name: "Update Branch Transport",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UBT",
        rtlMini: "شع",
        component: UpdateBranch,
        layout: "/admin",
      },
      {
        path: "/viewBranch",
        name: "view Branch",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VB",
        rtlMini: "شع",
        component: ViewBranch,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/labour-page",
        name: "Labour Page",
        rtlName: "عالتسعير",
        mini: "LP",
        rtlMini: "ع",
        component: LabourPage,
        layout: "/auth",
      },
      {
        path: "/pricing-page",
        name: "Pricing Page",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth",
      },
      // {
      //   path: "/rtl-support-page",
      //   name: "RTL Support",
      //   rtlName: "صودعم رتل",
      //   mini: "RS",
      //   rtlMini: "صو",
      //   component: RTLSupport,
      //   layout: "/rtl",
      // },
      // {
      //   path: "/timeline-page",
      //   name: "Timeline Page",
      //   rtlName: "تيالجدول الزمني",
      //   mini: "T",
      //   rtlMini: "تي",
      //   component: TimelinePage,
      //   layout: "/admin",
      // },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth",
      },
      // {
      //   path: "/lock-screen-page",
      //   name: "Lock Screen Page",
      //   rtlName: "اقفل الشاشة",
      //   mini: "LS",
      //   rtlMini: "هذاع",
      //   component: LockScreenPage,
      //   layout: "/auth"
      // },
      // {
      //   path: "/user-page",
      //   name: "User Profile",
      //   rtlName: "ملف تعريفي للمستخدم",
      //   mini: "UP",
      //   rtlMini: "شع",
      //   component: UserProfile,
      //   layout: "/admin",
      // },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth",
      },
    ],
  },
  
];
export default dashRoutes;
