import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { FcIphone, FcMultipleSmartphones, FcTwoSmartphones } from "react-icons/fc";
import { IoIosLaptop } from "react-icons/io";
import Table from "components/Table/Table.js";
import { FaDatabase } from "react-icons/fa";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DomainIcon from '@material-ui/icons/Domain';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AlarmIcon from '@material-ui/icons/Alarm';
import ComputerIcon from '@material-ui/icons/Computer';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import StorageIcon from '@material-ui/icons/Storage';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import LockIcon from '@material-ui/icons/Lock';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SettingsIcon from '@material-ui/icons/Settings';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import WebIcon from '@material-ui/icons/Web';

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

const useStyl = makeStyles(style);
const useStyles = makeStyles(styles);

export default function PricingPage() {
  const [tags, setTags] = React.useState(["pizza"]);
  const classes = useStyles();
  const classe = useStyl();
  const handleTags = regularTags => {
    setTags(regularTags);
  };
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Select the best plan for you</h2>
          {/* <h5 className={classes.description}>
            You have Free Unlimited Updates and Premium Support on each package.
          </h5> */}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}> BASIC SHARED PLAN </h6>
              <div className={classes.icon}>
                <IoIosLaptop className={classes.iconRose} />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                ₹ 34,914/-
              </h3>
              {/* <p className={classes.cardDescription}>
                2 and 10 Persons.
              </p>
              <p className={classes.cardDescription}>
                Company
              </p> */}
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      [
                        <WebIcon style={{ color: "green" }} />,
                        "Website with Admin Control",

                      ],
                      [
                        <PhoneAndroidIcon style={{ color: "red" }} />,
                        "Android App",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Language Supported, JAVA Tomcat v10.04 and React",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "vCPU 2 Cores",

                      ],

                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "1 GB RAM",

                      ],

                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "6 GB SSD Storage ",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "500 GB Bandwidth ",

                      ],
                      [
                        <CloseIcon style={{ color: "red" }} />,
                        "Customize",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Free Domain for 1 Year ",

                      ],

                    ]}
                  />
                </GridItem>
              </GridContainer>
              <Button round color="rose">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}> ADVANCED SHARED PLAN</h6>
              <div className={classes.icon}>
                <IoIosLaptop className={classes.iconRose} />
                <FcIphone />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                ₹ 59,828/-
              </h3>
              {/* <p className={classes.cardDescription}>
                2 and 10 Persons.
              </p>
              <p className={classes.cardDescription}>
                Company
              </p> */}
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      [
                        <WebIcon style={{ color: "green" }} />,
                        "Website with Admin Control",

                      ],
                      [
                        <PhoneAndroidIcon style={{ color: "green" }} />,
                        "Android App ",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Language Supported, JAVA Tomcat v10.04 and React",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "vCPU 2 Cores",

                      ],

                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "2 GB RAM",

                      ],

                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "15 GB SSD Storage ",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "1 TB Bandwidth ",

                      ],
                      [
                        <CloseIcon style={{ color: "red" }} />,
                        "Customize",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Free Domain for 1 Year ",

                      ],
                    ]}
                  />
                </GridItem>
              </GridContainer>
              <Button round color="rose">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>STANDARD VPS PLAN</h6>
              <div className={classes.icon}>
                <IoIosLaptop className={classes.iconRose} />
                <FcTwoSmartphones />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                ₹ 69,828/-
              </h3>
              {/* <p className={classes.cardDescription}>
                2 and 10 Persons.
              </p>
              <p className={classes.cardDescription}>
                Company
              </p> */}
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      [
                        <WebIcon style={{ color: "green" }} />,
                        "Website with Admin Control",

                      ],
                      [
                        <PhoneAndroidIcon style={{ color: "green" }} />,
                        "Android App with Admin Control",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Language Supported, JAVA Tomcat v10.04 and React",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "vCPU 2 Cores",

                      ],

                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "2 GB RAM",

                      ],

                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "15 GB SSD Storage ",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "1 TB Bandwidth ",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Customize",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Free Domain for 1 Year ",

                      ],
                    ]}
                  />
                </GridItem>
              </GridContainer>
              <Button round color="rose">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card pricing raised>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>CUSTOMIZED VPS PLAN</h6>
              <div className={classes.icon}>
                <IoIosLaptop className={classes.iconRose} />
                <FcMultipleSmartphones />
              </div>
              <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                ₹ 1,18,228/-
              </h3>
              {/* <p className={classes.cardDescription}>
                2 and 10 Persons.
              </p>
              <p className={classes.cardDescription}>
                Company
              </p> */}
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={12}>
                  <Table
                    tableData={[
                      [
                        <WebIcon style={{ color: "green" }} />,
                        "Website with Admin Control",

                      ],
                      [
                        <PhoneAndroidIcon style={{ color: "green" }} />,
                        "Android App with Admin Control and Labour Control",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Language Supported, JAVA Tomcat v10.04 and React",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "vCPU 2 Cores",

                      ],

                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "4 GB RAM",

                      ],

                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "30 GB SSD Storage ",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "2 TB Bandwidth ",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Customize",

                      ],
                      [
                        <CheckIcon style={{ color: "green" }} />,
                        "Free Domain for 1 Year ",
                      ],
                    ]}
                  />
                </GridItem>
              </GridContainer>
              <Button round color="rose">
                Choose plan
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>



      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Raising the Bar</h2>
          <h5 className={classes.description}>
          Amazing features gives unforgettable experience to everyone.
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><DomainIcon /> FREE Domain for 1st Year</h6>
                  <p>
                  One year of domain registration is included with each new VPS. After the initial year, the domain can be renewed at market price.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><ThumbUpIcon /> 30-Day Money-Back Guarantee</h6>
                  <p>
                  In case your are not satisfied by our service, you can cancel the hosting within 30 days of purchase of plan. And you will get full refund of hosting fees.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><QuestionAnswerIcon />Help & Support</h6>
                  <p>
                  with our managed services, get priority support and help whenever you need. you can call us at 022 4750 1787 from 10:00 AM to 07:00 PM.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><ThumbUpIcon />SSL Certificate</h6>

                  <p>
                  Each  SSL Certificate provided helps secure the connection between your websites and your visitors. This helps keep personal information, e-commerce transactions, and other sensitive data safeguarded.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><AlarmIcon />High Performance</h6>

                  <p>
                  We offer Admin control that is powerful, reliable and easy to use, with the advanced speed.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><ComputerIcon />Immediate Provisioning</h6>

                  <p>
                  Most of hosting solutions take hours to activate the Admin Control, here we make sure that you get instant activation and access of admin control.
                  </p>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={4}>
                  <h6><ThumbUpIcon />Guaranteed Resources</h6>
                  <p>
                    By utilizing only high-performance components, your websites
                    always perform at optimal levels. And thanks to our implementation
                    of KVM hypervisor, your resources are guaranteed to be available
                    at all times.
                  </p>
                </GridItem> */}
                <GridItem xs={12} sm={12} md={4}>
                  <h6><EnhancedEncryptionIcon />Control Panel</h6>

                  <p>
                  we do not provide VPS Access in any of our plans, we only give Admin Control to the clients.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><ThumbUpIcon /> Scalability</h6>

                  <p>
                  Start with what you need and move to more powerful options as your website grows. Our custom technology lets you upgrade in just a few clicks.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><FaDatabase size={20} /> Secured Data </h6>
                  <p>We understand that your privacy is important to you and we respect that. We ensure you that we will maintain complete confidentiality of the details that you have shared with us.</p>
                </GridItem>


              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>


      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Powerful, Flexible Hosting</h2>
          <h5 className={classes.description}>
            Our customisable and scalable platform delivers everything your website needs.
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>

                <GridItem xs={12} sm={12} md={4}>
                  <h6><StorageIcon />Multi-Server Management</h6>

                  <p>
                    Need more than one server? You can add more VPS,
                    or even Shared and Dedicated hosting services to
                    your account and effortlessly manage everything in
                    one place.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><ThumbUpIcon />Access Control</h6>

                  <p>
                    Create passwords that grant access to specific aspects of your account.
                    Issue one for ownership information, one for server administration,
                    and/or one master password for everything
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><ThumbUpIcon />Advanced Capabilities</h6>

                  <p>
                    Our VPS servers are designed from the ground up to support everything
                    you could need, like Secure Shell (SSH) access, server-side includes,
                    log file access and web-based managers for files, databases, and email.
                    Customise your own .htaccess files, cron jobs, and php.ini files.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><DataUsageIcon /> Database Management</h6>

                  <p>
                    Easily organize, edit, and manage databases directly on
                    the server with our custom-designed database manager.
                  </p>
                </GridItem>

              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>


      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Optional Add-Ons</h2>
          <h5 className={classes.description}>
            Enhance your account with marketing, security, email upgrades, and more.
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>

                <GridItem xs={12} sm={12} md={4}>
                  <h6><SettingsIcon />Managed Services</h6>

                  <p>
                    Get round-the-clock advanced expert support & solutions for
                    VPS Hosting, including server setup, migration, hardening,
                    proactive monitoring & a customized server eco-system.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><VpnKeyIcon />Domain Privacy</h6>

                  <p>
                    Prevent identity theft, spam and phishing attacks by masking
                    the personal information associated with your domain name.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h6><LockIcon />SiteLock</h6>

                  <p>
                    Defend your websites against attacks and malware by
                    detecting and fixing vulnerabilities and threats before
                    they cause problems.
                  </p>

                </GridItem>

              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>



      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Best Managed VPS Hosting Services</h2>
          <h5 className={classes.description}>
            Purchase our managed virtual private server services add-on to empower your business with the
            technical expertise of our server specialists with best in class support.
          </h5>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <h6><SettingsIcon />24/7/365 Advanced Support</h6>


                  <p>
                    With our managed vps hosting services, get on-demand
                    priority support, which includes constant server monitoring
                    & 99.99% uptime guarantee.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <h6><VpnKeyIcon />Technical Expertise</h6>

                  <p>
                    Our server specialists help you build a robust VPS
                    server with initial setup, website migration, server hardening,
                    managing storage & complete server management.
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <h6><LockIcon />Customized Environment</h6>

                  <p>
                    Our managed services team provides assistance for application
                    stack optimizations, 3rd party application installations,
                    administrative control & full root access.
                  </p>

                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
