import React, { useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { useHistory, useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Card } from "@material-ui/core";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { TiPhoneOutline } from "react-icons/ti";
import styles from "assets/jss/material-dashboard-pro-react/views/viewCrmPrintStyle";
import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);

export default function CrmlistPrint() {
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [currentDate, setCurrentDate] = React.useState("");

  const classTable = useStyles();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { search } = useLocation();
  const city = new URLSearchParams(search).get("city");
  const firstname = new URLSearchParams(search).get("firstname");
  const createDate = new URLSearchParams(search).get("createDate");

  const { state } = useLocation();
  // console.log(state);

  useEffect(() => {
    // let current = moment().format("YYYY-MM-DD HH:MM");
    let current = new Date();
    const d = state.map((m) => {
      return m.createDate;
    });
    const dates = d.sort();
    const a = [dates[0], dates[dates.length - 1]];
    setCurrentDate(current);
    setStartDate(a[0]);
    setEndDate(a[1])
  }, []);

  setTimeout(() => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  }, 0);

  return (
    <div className={classTable.productPage}>
      <Card>
        <GridContainer justify="center" alignItems="center">
          <div className={classTable.container}>
            <GridContainer className={classTable.borderLine}>
              <GridItem md={2} sm={2} xs={2}>
                <img
                  src={logo}
                  style={{ marginTop: "10px", height: "100px" }}
                />
              </GridItem>
              <GridItem
                md={8}
                sm={8}
                xs={8}
                className={
                  classTable.mlAuto +
                  " " +
                  classTable.mrAuto +
                  " " +
                  classTable.textCenter
                }
              >
                <span className={classTable.invoiceName}>Calling Report</span>
                <br />
                <span className={classTable.companyName}>
                  {userObj && userObj.company
                    ? userObj.company
                    : userObj &&
                      userObj.firstname + " " + userObj &&
                      userObj.lastname}
                </span><br />
                <span className={classTable.invoicedetail}>
                {userObj && userObj.mobile ? <ImMobile /> : ""}{" "}
                {userObj && userObj.mobile ? userObj.mobile : ""}{" "}
                {userObj && userObj.whatsapp ? <IoLogoWhatsapp /> : ""}{" "}
                {userObj && userObj.whatsapp
                  ? userObj.whatsapp
                  : ""}
              </span><br />
              <span className={classTable.invoicedetail}>{"Current Date:- " + currentDate}</span>
              </GridItem>
              <GridItem
                md={2}
                sm={2}
                xs={2}
                className={classTable.originalCopy}
              >
                <span>Original Copy</span>
              </GridItem>
              <GridItem
                md={12}
                sm={12}
                xs={12}
                className={
                  classTable.mlAuto +
                  " " +
                  classTable.mrAuto +
                  " " +
                  classTable.textRight
                }
              >
             <span className={classTable.dateFormat}> {"from " + startDate + " to " + endDate}</span>
             </GridItem>
            </GridContainer>
            
            <Table
              tableHead={[
                <span className={classTable.tableHead} key="sr">
                  Sr. No.
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headLeft}
                  key="des"
                >
                  Company or First Name
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headLeft}
                  key="date"
                >
                  Date
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headLeft}
                  key="mobile"
                >
                  Mobile
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headLeft}
                  key="whatsapp"
                >
                  WhatsApp
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headLeft}
                  key="email"
                >
                  Email
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headLeft}
                  key="type"
                >
                  Type
                </span>,
                <span
                  className={classTable.tableHead + " " + classTable.headLeft}
                  key="city"
                >
                  City
                </span>,
              ]}
              tableData={[
                ...state.map((c, index) => [
                  <span className={classTable.listNumber} key={c.id}>
                    {index + 1}
                  </span>,
                  <span
                    className={
                      classTable.headLeft + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c && c.company
                      ? c.company
                      : c && c.firstname + " " + c && c.lastname}
                  </span>,
                  <span
                    className={
                      classTable.headLeft + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.createDate}
                  </span>,
                  <span
                    className={
                      classTable.headLeft + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.mobile}
                  </span>,
                  <span
                    className={
                      classTable.headLeft + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.whatsapp}
                  </span>,
                  <span
                    className={
                      classTable.headLeft + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.email}
                  </span>,
                  <span
                    className={
                      classTable.headLeft + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.type}
                  </span>,
                  <span
                    className={
                      classTable.headLeft + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.city}
                  </span>,
                ]),
              ]}
            />
            <hr style={{ borderBottom: "1px solid #00000066" }} />
            <div className={classTable.brand}>
              <Button
                className={classTable.print}
                color="rose"
                round
                onClick={() => {
                  window.print();
                }}
              >
                Print
              </Button>
            </div>
          </div>
        </GridContainer>
      </Card>
    </div>
  );
}
